


















































































































































































.custom-player {
  margin: 15px 0;

  .audio-player {
    height: 38px;
    width: 100%;
    background: #ddd;
    box-shadow: 0 0 3px 0 #ddd;
    color: #444444;
    font-size: 0.65em;
    overflow: hidden;
    display: grid;
    grid-template-rows: 6px auto;
    border-radius: 35px;
    margin: 0 auto;
    z-index: 5;

    .speed-slider {
      background: #b7b7b7;

      z-index: 9;
      height: 30px;
      cursor: pointer;

      .el-slider {
        height: 0;
        top: 16px;
        position: absolute;
        width: calc(100% - 30px);
        max-width: calc(790px - 30px);
        margin: 0 15px;

        ::v-deep .el-slider__runway {
          background: #b7b7b7;
        }

        ::v-deep .el-slider__button-wrapper {
          height: 20px;
          width: 20px;
          top: -7px;
        }

        ::v-deep .el-slider__bar {
          background: #a5a5a5;
        }
      }
    }

    .timeline {
      background: white;
      width: 100%;
      position: relative;
      cursor: pointer;
      box-shadow: 0 2px 10px 0 #0008;

      .progress {
        background: #b7b7b7;
        width: 0;
        height: 100%;
        transition: 0.25s;
      }
    }

    .controls {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      height: 26px;
      padding: 0 20px;

      .time, .name, .speed {
        margin-top: 3px;
      }

      .other-controls {
        margin-top: 3px;
        font-size: 21px;
        margin-left: 5px;

        i {
          margin-top: 8px;
          display: inline-block;
          margin-left: 5px;
          cursor: pointer;
        }

      }

      > * {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .toggle-play {
        &.play {
          cursor: pointer;
          position: relative;
          left: 0;
          height: 0;
          width: 0;
          border: 7px solid #0000;
          border-left: 13px solid white;

          &:hover {
            transform: scale(1.1);
          }
        }

        &.pause {
          height: 15px;
          width: 20px;
          cursor: pointer;
          position: relative;

          &:before {
            position: absolute;
            top: 0;
            left: 0;
            background: white;
            content: "";
            height: 15px;
            width: 3px;
          }

          &:after {
            position: absolute;
            top: 0;
            right: 8px;
            background: white;
            content: "";
            height: 15px;
            width: 3px;
          }

          &:hover {
            transform: scale(1.1);
          }
        }
      }

      .time {
        display: flex;

        > * {
          padding: 2px;
        }
      }

      .volume-container {
        cursor: pointer;

        .volume-button {
          font-size: 25px;
          display: flex;
          align-items: center;

          .volume {
            transform: scale(0.7);
          }
        }

        position: relative;
        z-index: 2;

        .volume-slider {
          position: absolute;
          left: -3px;
          top: 5px;
          z-index: -1;
          width: 0;
          height: 15px;

          background: #b7b7b7;
          box-shadow: 0 0 20px #000a;
          transition: .25s;
          transform: rotate(180deg);
          display: flex;
          justify-content: flex-start;

          .volume-percentage {

            background: #fff;
            height: 100%;
            width: 75%;
          }
        }

        &:hover {
          .volume-slider {
            transform: rotate(180deg);
            left: -103px;
            width: 100px;
          }
        }
      }
    }
  }
}
