




















































.list{
  max-height: 300px;
  overflow: auto;
  display: grid;
  gap: 1px;
  border: 1px solid #DCDFE6;
  border-radius: 10px;
  padding: 10px;
  .item{
    cursor: pointer !important;
    &:hover{
      label{
        p{
          color: #24387f;
          text-decoration: underline;

        }
      }
    }
    label{
      display: flex;
      align-items: center;
      gap: 3px;
      p{
        margin-bottom: 3px;
        font-size: 12px;
      }
      div{
        order: -1;
        display: inline-block;
        input{
          z-index: -21 ;
        }
      }

    }
  }
}

