





















































































@import "src/assets/css/project-variables";
.item {
  border-radius: 10px;
  user-select: none;
  border: solid 1px #8080802e;
  box-shadow: 2px 2px 7px 0px #8080802e;
  height: 100%;

  .item-body__progress {
    padding: 0 10px 10px;
  }


  .item-header {
    padding: 10px 10px 0;
    display: flex;
    justify-content: space-between;

    h4 {
      margin: 0;
      color: #555;
      font-weight: 500;
      font-size: 13px;
    }

    button {
      cursor: pointer;
      padding: 5px;
      border: none;
      background: none;

      i {
        color: #bababa;
      }
    }
  }

  .item-body {
    padding: 0 15px;
    margin-bottom: 10px;
    height: 300px;
    overflow: auto;

    &__list {

      .show-call-dialog {
        color: $--color-primary;
        cursor: pointer;
      }

      li {
        font-size: 11px;
        color: #555;
        font-weight: normal;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        position: relative;
        padding-left: 20px;
        
        .el-icon-sort {
          position: absolute;
          top: 11px;
          left: 0;
          transform: rotate(90deg);
          color: red;
          font-weight: bold;
        }
        
        span {
          &:first-child {

          }

          transform: translateY(8px);
          background: #fff;
        }
        ::v-deep .category-calls .popover-button {
          padding: 0 2px;
        }
      }
    }
  }


  .item-footer {
    position: relative;
    background: #ececec;

    &__performed {
      position: absolute;
      top: -10px;
      right: 10px;

      span {
        color: #fff;
        font-size: 12px;
        border-radius: 2px;
        padding: 5px 5px;
        background: gray;
      }
    }

    &__content {

      padding: 15px 20px 10px;

      .points {
        font-size: 11px;
        color: #8a8a8a;
      }

      .score {
        font-size: 11px;
        color: #8a8a8a;
      }
    }
  }
}


