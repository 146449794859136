





























































































































































@import "./src/assets/css/project-variables";

::v-deep .el-tabs__nav-wrap {
  display: flex;
  justify-content: center;
}

::v-deep .el-tabs__content {
  background: $--background-color-base;
}

.container {
  padding: 15px;
  min-height: calc(100vh - 187px);

  .title {
    h4 {
      color: #555;
      margin: 0;
      font-size: 14px;
      font-weight: bold;
    }
  }

  .dict {
    margin-bottom: 20px;
  }

  .content {
    padding: 10px 0;
  }
}

.pending-calls {
  padding: 20px 0 0 15px;
  h5 {
    margin-bottom: 0;
  }
}

::v-deep .el-submenu__title, ::v-deep .el-menu-item {
  font-size: 13px;
  height: 50px;
}

::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
  height: 50px;
}

.btnAdd {

}

::v-deep .el-submenu__title, ::v-deep .el-menu-item {
  padding: 0 55px;
}

.title {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
  color: #555;
}

::v-deep{
  .modalAddDictGroup{
    .el-dialog__title{
      color: #555;
      font-weight: bold;
    }
    .modalRow{
      margin-bottom: 10px;
    }
    label{

      p{
        margin-bottom: 5px;
        color: #555;
      }
    }
    .el-input,.el-select,.el-color-picker,.el-color-picker__trigger{
      width: 100%;
      border-radius: 10px;
    }
    .el-color-picker__color,.el-color-picker__color-inner{
      border-radius: 10px;
      border: none;
    }

  }
}


