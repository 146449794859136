




























































































































































































































































































































.container {
  background: #fff;
  padding: 1rem;

  ::v-deep {
    .input-search {
      margin-right: 20px;
      max-width: 220px;
      input {
        height: 30px;
        border: none;
        border-bottom: solid 1px black;
        border-radius: 0;

      }
      .el-input__suffix {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .title {
    display: grid;
    grid-template-columns: 1fr 200px 190px;
    gap: 30px;
    h3 {
      margin-bottom: 10px;
    }
    .my-button {
      height: 28px;
    }
  }
  .el-breadcrumb {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .dictionaries-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 20px;

    @media (max-width: 1700px) {
      & {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
    @media (max-width: 1500px) {
      & {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
    @media (max-width: 1200px) {
      & {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

}

