






























































































































































































































































































































































































































































































//.pdf-content {
//  page-break-inside: avoid;
//}
//
//.pdf-content * {
//  page-break-inside: avoid;
//}
.container {
  display: flex;
  width: 100%;

  h2 {
    text-align: center;
    color: #555;
    margin-bottom: 5px;
  }

  .result-info {
    border-right: solid 1px #cccccc;
    padding: 20px;
    width: 50%;

    ::v-deep .el-collapse {
      border-top: none;
    }
  }

  .call-info {
    padding: 20px;
    width: 50%;

    ::v-deep .el-collapse-item__header {
      color: #555;
      font-weight: bold;
    }

    ::v-deep .el-collapse {
      border-top: none;
    }

    ::v-deep .el-collapse-item__wrap {
      border-bottom: none !important;
    }

    &__container {
      .info-block-greed {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        font-size: 11px;

      }

      div {
        color: #555;

        strong {
          color: #555 !important;
        }
      }
    }
  }

  .accord-title {
    strong {
      color: #555;
    }
  }
}

@media (max-width: 800px) {
  .container {
    display: flex;
    flex-direction: column;

    .result-info {
      width: 100%;
    }

    .call-info {
      order: -1111;
      width: 100%;
    }
  }
}

.pdf-content {
  width: 100%;

  .separator {
    margin: 10px 0;
    border: solid 1px #b5b5b5;
  }

  p {
    color: black;
  }

  .pdf-content-header {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;

    &__left {
      justify-self: flex-start;
      width: 100%;

      img {
        max-width: 300px;
        width: 100%;
      }

      div {
        margin-left: 0px;
        max-width: 350px;
        width: 100%;

        p {
          text-align: center;
          width: 100%;

          &:first-child {
            text-align: center;
            margin-bottom: 10px;
          }
        }
      }
    }

    &__right {
      width: 100%;

      .call-info {
        margin-top: 30px;
        width: 100%;

        .info-block-greed {
          display: flex;
          gap: 30px;

          .first {
            min-width: 300px;
          }

          .second {
            min-width: 350px;
          }

        }
      }
    }
  }

  .dataRow {
    display: flex;

  }
}

.pdfCheckListItem {
  max-width: 350px !important;
  overflow: hidden;
}

.countCheck {
  margin-bottom: 10px;
}
.button_go_link{
  background: #ececec;
  padding: 2px 7px;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  top: 28px;
  right: 10px;
  border: none;
  font-size: 10px;
  color: #555;
  &:hover{
    color: #2b51b9;
  }
}

