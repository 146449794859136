




















































































































































































































































































































































@import "./src/assets/css/project-variables";
.categories {
  background: #e5e5e5;
  padding:  0 30px;
  .empty {
    font-size: 14px;
    color: indianred;
  }
  .category {
    a {
      display: inline-block;
      color: $--color-text-primary;
      margin-bottom: 10px;
    }
  }
  h1 {
    margin-left: 10px;
  }
  margin: 0;
  h4 {
    margin-bottom: 10px;
    font-size: 19px;
  }
  h5 {
    color: $--color-danger;
    margin-bottom: 10px;
  }
  .general-info {
    .el-col {
      background: #fff;
      padding: 30px;
      border-radius: 5px;
      margin: 10px;
      box-shadow: 0 5px 14px #d3d8e7, 0 4px 6px #edf1fc;
    }
  }
  .trend {
    background: #fff;
    padding: 30px;
    border-radius: 5px;
    margin: 10px;
    box-shadow: 0 5px 14px #d3d8e7, 0 4px 6px #edf1fc;
    &__description {
      font-size: 14px;
      color: #b5b5b5;
      i {
        font-size: 27px;
        color: #000;
        cursor: pointer;
        position: absolute;
        right: 0;
      }
      .el-col {
        > span {
          &:nth-child(odd) {
            color: #000;
            font-weight: 900;
          }
          &:nth-child(even) {
            font-size: 16px;
          }
          &.color {
            display: inline-block;
            margin-right: 5px;
            width: 12px;
            height: 12px;
            top: 5px;
            left: -15px;
          }
        }
      }
    }
  }
  i.el-icon-circle-plus-outline {
    position: absolute;
    top: 5px;
    right: 0;
    color: #b5b5b5;
    transition: all 0.1s ease-out;
    font-weight: bold;
    font-size: 16px;
    &:hover {
      transform: scale(1.1);
    }
  }
  .dictionaries, .checklists {
    p {
      color: #b5b5b5;
      margin-bottom: 10px;
      margin-left: 15px;
      font-size: 16px;
      cursor: pointer;
    }
  }
}
