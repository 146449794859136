










































































































.chart-data {
  &__row {
    display: grid;
    > div {
      font-size: 14px;
      margin-bottom: 10px;
      color: #b5b5b5;
      > div {
        width: 12px;
        height: 12px;
        text-align: right;
        position: relative;
        right: -60px;
        top: 5px;
      }
    }
  }
}
