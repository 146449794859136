





















































.el-menu {
  border: none;
  width: 100%;
  margin-top: 73px;
  .el-menu-item {
    height: auto;
    padding:25px 3px 25px 0!important;
    border-left: 5px solid transparent;
    box-sizing: border-box;
    &:hover, &:focus, &.is-active{
      background-color: #eaf0fd;
      border-color: #4779ca;
    }

    i {
      &.menu-icon {
        text-align: center;
        line-height: 1;
        font-size: 28px;
        color: #555555;
        display: flex;
        justify-content: center;
      }
      &.location-icon {

      }
      &.dictionary-and-checklist-icon {

      }
      &.search-icon {

      }
      &.violation-icon {

      }
      &.trend-icon {

      }
      &.constructor-icon {

      }
    }


  }
}

