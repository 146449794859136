


































































































































































































.dictionary-form-container {
  h4 {
    text-align: center;
    margin-bottom: 10px;
  }

  ::v-deep .el-form {
    background: #fff;
    padding: 20px;
    border-radius: 4px;

    &-item {
      margin-bottom: 20px;

      &__label {
        font-weight: bold;
        line-height: 1;
      }

      &__content {
        line-height: 1;

        .el-switch {
          margin-top: -4px;
        }

        .el-color-picker {
          margin-top: -15px;
        }
      }
    }
  }

  .buttons {
    text-align: right;
    margin-bottom: 25px;
  }
}

