























































































































































































































































































.dictionaries-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: 1700px) {
    & {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
  @media (max-width: 1500px) {
    & {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  @media (max-width: 1200px) {
    & {
      grid-template-columns: 1fr 1fr;
    }
  }
}


.add {
  text-align: right;
  margin-bottom: 10px;
}

.top-block {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    margin: 0;
  }
}

.search-block {
  margin-bottom: 20px;
}

.title {
  h4 {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 700;
    color: #555;
  }
}

.btn-wrapper {
  display: flex;
  gap: 15px;

  button {
    height: 30px;
    font-weight: 500;
    font-size: 12px;
    &:last-child{
      width: 140px;
    }
  }
}

::v-deep {
  .input-search {
    margin-right: 20px;
    max-width: 220px;


    input {
      height: 30px;
      border: none;
      border-bottom: solid 1px black;
      border-radius: 0;

    }

    .el-input__suffix {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

