











































.dictionaryIsDashboard {
  display: grid;
  width: 100%;

  .h2-title {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 450;
    color: #555;
  }
  .content {
    width: 100%;
    display: grid;
    grid-template-columns:  1fr 1fr 1fr 1fr;
    gap: 20px;
    transition: .3s;
    @media (max-width: 1600px) {
      & {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
    @media (max-width: 1200px) {
      & {
        grid-template-columns: 1fr  1fr;
      }
    }
  }



}
