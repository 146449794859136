









































































































































































































































































































































































































































































































































































.container {
  padding: 15px;

  .content {

    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    background: #fff;
    min-height: 75vh;

    .title {
      margin-bottom: 30px;

      h4 {
        margin-bottom: 0;
        font-size: 18px;
        font-weight: 700;
        color: #555;

        span {
          font-weight: normal;
          font-size: 14px;
        }
      }
    }

    .controls {
      width: 100%;
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      gap: 40px;
    }

    .disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    .info {
      max-height: 650px;
      overflow-y: auto;

      display: grid;
      grid-template-columns: 3fr 1fr;
      margin: 10px 0;
      gap: 20px;


    }

    i.el-icon-sort {
      transform: rotate(90deg);
      color: red;
    }

    .checklist {
      min-width: 400px;
      margin-right: 10px;
      border-radius: 5px;
      border: solid 1px #8080802e;
      box-shadow: 2px 2px 7px 0 #8080802e;
      padding: 10px;
      position: sticky;
      overflow: auto;
      top: 0;


      h5 {
        margin: 0;
        color: #555;
        font-weight: 500;
        font-size: 12px;
        text-align: center;
      }

      ul {
        margin: 10px 0;

        li {

          display: grid;
          grid-template-columns: 7fr 1fr 1fr 1fr;
          margin-bottom: 5px;
          gap: 1px;
          border-radius: 5px;

          &:last-child {
            margin-bottom: 0 !important;
          }

          &:nth-child(even) {
            background: #eef3ff;
          }

          p {
            line-height: 1;
            padding: 5px 10px;
            margin: 0;

            &.checklistName {
              color: #555;
              cursor: pointer;
              font-size: 10px;
              padding-right: 0;
            }

            &.work_count {
              color: green;
              font-size: 10px;

            }

            &.not_word_count {
              color: red;
              font-size: 10px;
            }

            &.work_count,
            &.not_word_count {
              justify-self: center;
              justify-content: center;
              align-items: center;
              display: flex;
            }

          }

        }

        .checklistLegend {
          padding: 0 !important;

          p {
            padding: 0 !important;
            font-size: 9px !important;

            text-align: center;

            &:first-child {
              border: none;
              text-align: start;
              padding: 0 10px !important;
            }
          }
        }
      }

    }

    .calls {
      position: relative;
      overflow: auto;


      .legend {
        border-radius: 5px;
        background: #24387f;
        padding: 5px;
        position: sticky;
        top: 0;
        width: max-content;
        color: #fff;


        //.background{
        //  position: absolute;
        //  width: 100%;
        //  top: 0;
        //  right: 0;
        //  left: 0;
        //  bottom: 0;
        //  background: red;
        //  height: 100%;
        //}
      }

      .callList {
        width: 100%;
        max-height: 568px;

      }

      .callList li > div {
        cursor: pointer;
        transition: background-color .2s linear;

        &:hover {
          background: #ececec;
        }

        padding: 5px 0;

        > div {
          &:nth-child(3) {
            min-width: 180px;
          }
        }
      }

      .legend > div {
        &:nth-child(3) {
          min-width: 180px;
        }
      }

      .callList li > div, .legend {
        display: flex;


        div {
          min-width: 65px;
          font-size: 9px;
          text-align: center;
        }

        .date-call {
          min-width: 100px;
        }
      }
    }


  }
}

.pointer {
  cursor: pointer;
}

.separator {
  margin: 5px 0;
  height: 1px;
  background: #b5b5b5;
  width: 100%;
}

