

































































































































































.edit-organization {
  .collapse-title-container {
    .collapse {
      &-title, &-content {
        display: grid;
        grid-template-columns: 3fr 1fr 2fr 2fr 2fr 2fr;
        > p {
          text-align: center;
          &:first-child, &:last-child {
            text-align: left;
          }
        }
      }
    }
    p {
      margin-bottom: 0;
      line-height: 1;
    }
    .collapse {
      &-title {
        margin-top: 5px;
        p {
          font-size: 10px;
        }
      }
      &-content {
        margin: 5px 0 5px 0;
        p {
          color: #555555;
          font-size: 14px;
          line-height: 1.6;
        }
      }
    }


  }
  .calls-info {
    margin: 10px 20px;
    background: #fff;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #eee;
    &__title, &__content {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      p {
        color: #555555;
        margin-bottom: 0;
        text-align: center;
      }
    }
    &__title {
      p {
        margin-bottom: 10px;
        font-size: 10px;
      }

    }
    &__content {
      p {
        font-size: 12px;
        font-weight: bold;
      }
    }
  }
  .users {
    &__header {
      display: flex;
      justify-content: space-between;
      padding: 5px 20px 0 20px;
      h2 {
        margin-top: 10px;
        font-weight: bold;
        margin-left: 20px;
      }
      .new-user {
        font-size: 10px;
        border: 1px solid #555555;
        color: #555555;
        border-radius: 4px;
        padding: 2px 10px;
        cursor: pointer;
      }
    }
    h2 {
      margin: 0;
      font-size: 12px;
      color: #555555;
    }

    &__container {
      margin: 0 10px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      .user {
        background: #fff;
        margin: 10px;
        padding: 10px;
        border-radius: 4px;
      }
    }
  }
}

