









































































































.category-dictionaries-container {
  margin: 15px;
  max-height: 400px;
  overflow-y: auto;
  color: #252525;
  h4 {
    margin-bottom: 20px;
  }
}
.dictionary-title {
  > .el-col {
    text-align: center;
    margin-bottom: 10px;
    font-weight: bold;
    &:first-child{
      text-align: left;
    }
  }

}
.dictionary {
  > .el-col {
    text-align: center;
    &:first-child{
      text-align: left;
    }
    > span {
      display: inline-block;
      width: 20px;
      height: 20px;
    }
  }
}
