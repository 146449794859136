

















.my-button{
  font-family: inherit;
  font-weight: bold;
  font-size: 14px;
  background: #24387f;
  color: #fff;
  padding: 7px 13px;
  border: solid 1px #24387f;
  border-radius: 5px;
  cursor: pointer;
  transition: .3s;
  &:hover{
    transition: .3s;
    background: #626ca4;
    border: solid 1px #626ca4;
  }
}
