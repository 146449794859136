










































































































































































































































































































































































































































































.statistic {
  margin-top: 20px;
  overflow-x: auto;

  .callInfo {
    //border-top: solid 1px #EBEEF5;

    &__top {
      display: grid;

      div {
        border-bottom: solid 1px #EBEEF5;
        padding: 5px 0;
        font-weight: bold;
        color: #555;
        text-align: center;

        &:nth-child(even) {
          border-left: solid 1px #EBEEF5;
          border-right: solid 1px #EBEEF5;
        }

        &:first-child {
          //border-left: solid 1px #EBEEF5;
        }

        &:last-child {
          //border-right: solid 1px #EBEEF5;
          border-right: none;
        }
      }
    }

    &__content {
      display: grid;

      &-default {
        padding: 15px 0;
        //border-bottom: solid 1px #EBEEF5;

        ul {
          margin-bottom: 20px;

          li {
            text-align: center;
            height: 20px;
            color: #555;
            font-weight: 500;
            font-size: 12px;
            margin-bottom: 3px;
          }
        }

        &:nth-child(even) {
          border-right: 1px solid #EBEEF5;
          border-left: 1px solid #EBEEF5;
        }

        &:last-child {
          //border-right: 1px solid #EBEEF5;
          border-right: none;
        }
      }

      &__content-users {
        &:last-child {
          border-right: solid 1px #ebebeb;
        }
      }
    }
  }

  .checklistInfo {

    display: grid;

    &__default {
      border-right: solid 1px #EBEEF5;
      border-top: solid 1px #EBEEF5;

      &:last-child {
        border-right: none;
      }

      ul {
        width: 230px;
        padding: 20px 0;
        margin-bottom: 0;
        border-bottom: solid 1px #EBEEF5;

        .el-collapse {
          ::v-deep .el-collapse-item__content {
            border-right: solid 1px #EBEEF5;
          }
        }

        &:first-child {
          padding-top: 10px;
        }

        &:last-child {
          border-bottom: none;
        }


        li {
          color: #555;
          //font-weight: 500;
          font-size: 12px;
          margin-bottom: 3px;
          text-overflow: ellipsis !important;
          white-space: nowrap;
          overflow: hidden;
          max-width: 280px;
          padding-right: 20px;
          height: 20px;

          &:nth-child(4) {
            margin-bottom: 10px;
          }

          span {
            font-weight: bold;
            font-size: 12px;
          }
        }

        .dict {
          display: flex;
          align-items: center;
          gap: 5px;
          font-size: 10px;
          //line-height: 1;
          margin-bottom: 5px;
          height: 20px;

          span {
            width: 35px;
            min-width: 35px;
            height: 15px;
            border-radius: 4px;
            text-align: center;
            color: #fff;
            background: #bababa;
            vertical-align: middle;
            line-height: 1.5;
          }

          i {
            transform: rotate(90deg);
            color: red;
            line-height: 1;
          }
        }
      }

      &:last-child {
        ul {
          .el-collapse {
            ::v-deep .el-collapse-item__content {
              border-right: none !important;;
            }
          }
        }
      }
    }

    &__client {

      ul {
        li {
          text-align: center;
          justify-content: center;
          height: 20px;
          padding: 0 20px;
        }

        .dict {
          width: 100%;
          text-align: center !important;
          height: 20px;
          padding: 0 20px;
        }
      }
    }
  }

  .dictionaryInfo {
    display: grid;

    &__default {
      border-top: solid 1px #EBEEF5;
      border-right: solid 1px #EBEEF5;
      padding: 10px 0;

      &:last-child {
        border-right: none;
      }

      .system, .client {
        p {
          color: #555;
          margin-bottom: 10px;
          font-weight: 500;
        }

        ul {
          margin-bottom: 10px;

          li {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 10px;
            margin-bottom: 5px;
            height: 20px;
            color: #555;
            text-overflow: ellipsis !important;
            white-space: nowrap;
            overflow: hidden;
            max-width: 280px;
            padding-right: 20px;

            span {
              width: 35px;
              min-width: 35px;
              height: 15px;
              border-radius: 4px;
              text-align: center;
              color: #fff;
              background: #EBEEF5;
              vertical-align: middle;
              line-height: 1.5;
            }

            i {
              transform: rotate(90deg);
              color: red;
              line-height: 1;
            }
          }
        }
      }
    }

    &__client {
      .system, .client {
        padding: 0 20px;

        p {
          text-align: center;
          opacity: 0;
        }

        ul {
          li {
            justify-content: center;
          }
        }
      }
    }
  }
}

.wrapper {
  display: grid;
}

.category-name {
  display: grid;

  div {

    border: solid 1px #EBEEF5;
    border-top: none;
    border-bottom: none;
    border-left: none;
    padding: 15px 0;
    font-weight: bold;
    color: #555;
    text-align: center;

    &:last-child {
      border-right: none;
    }
  }

}

::v-deep {
  .el-collapse-item__wrap {
    border-bottom: none;
  }

  .el-collapse-item__content {
    padding-bottom: 0;
  }

  .el-collapse-item__wrap {
    overflow-x: auto;
  }

  .el-collapse-item__header {
    //height: 10px;
    color: #24387f;
    font-size: 15px;
  }

  .checklists-collapse {
    .el-collapse {
      border-top: none;
    }

    .el-collapse-item__header {
      //margin-top: 10px !important;
      height: 35px;
      background: inherit;
    }

    .el-collapse-item__header i {
      //display: none;
    }

    .el-collapse-item__wrap {
      border-bottom: none;
    }

    .el-collapse-item__content {
      padding-top: 10px;
      //border-right: solid 1px #EBEEF5;
      border-bottom: none;

      &:last-child {

      }

      li {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.not-use {
  > li {
    opacity: 0;

    &:first-child {
      display: flex;
      //color: red !important;
      opacity: 1;
    }
  }

  div {
    li {
      opacity: 0;
    }
  }
}

::v-deep{
  .el-collapse-item__header{
    height: 25px;
    border-top: solid 1px #EBEEF5;
  }
}

