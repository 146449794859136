











































































































.el-row {
  > h4 {
    white-space: nowrap;
    margin-bottom: 0;
  }
  > p {
    color: #d5d5d5;
    font-size: 12px;
  }
  .mt15 {
    margin-top: 10px;
    .description {
      margin-left: 15px;
      > .el-row {
        &:first-child {
          color: #b5b5b5;
          font-size: 14px;
        }
        > .el-col {
          &:first-child {
            font-size: 14px;
            font-weight: 900;
            color: #3c3d44;
          }
        }
      }
    }
  }
}

