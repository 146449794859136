







































































































.add-files {
  transition: .3s;

  border-radius: 10px;
  border: solid 1px rgba(0, 0, 0, 0.13);
  width: 100%;
  min-height: 340.88px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  background: #fff;

  &.dragover {
    background-color: #fdf6f2;
    outline-offset: -17px;
  }

  div {
    position: relative;
    padding: 20px;

    &:before, &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background: black;
      left: 0;

    }

    &:after {
      transform: rotate(-90deg);
    }
  }

  p {
    color: black;
    padding: 20px;
    margin: 0;
    font-size: 20.555555555px;
    text-align: center;
  }

  .label {
    span {
      cursor: pointer;
      font-weight: bold !important;
      color: #24387f;
    }

    i {
      font-size: 12px;
      color: #b5b5b5;

      b {
        font-size: 12px;
        text-decoration: underline;
      }
    }

  }
}

#inputFile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  position: absolute;
  z-index: -10;

  &:focus {
    outline: none;
  }
}


