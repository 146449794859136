



















































































































































































.recognized-text.noFix {
  max-height: unset;
}

.recognized-text {
  padding: 0 10px;
  margin-top: 10px;
  max-height:65vh;
  overflow-y: auto;

  ul {
    display: grid;

    li {
      word-wrap: break-word;
      display: inline;
      flex-wrap: wrap;
      font-size: 11px;

      span {
        font-weight: 500;
      }

      i {
        display: inline-flex;
        font-style: normal;
        padding: 0 5px;

      }

      .complited {
        background: #8695c7;
        color: #fff;
      }
    }
  }

  .client-text {
    span {
      color: #24387f;
    }
  }

  .employee-text {
    span {
      color: #24387f;
      color: red;
    }

  }
}

.highlight {
  background: #000 !important;
  color: #fff !important;
}

.btn_copy {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  //flex-direction: column;
  align-items: center;
  button{
    max-width: 110px;
    background: unset;
    font-size: 10px;
    cursor: pointer;
    color: #24387f;
    padding: 2px;
    margin-right: 5px;
    line-height: 1;
    border: solid 1px #24387f ;
    border-radius: 5px;
    font-weight: 500;
  }
  p{
    padding: 2px;
    border: solid 1px inherit ;
    border-radius: 5px;
    font-size: 10px;
    margin-bottom: 0;
    line-height: 1.1;
    color: #505050;
    font-weight: 500;
    margin-right: 5px;
  }
  .copy_employee {

  }

  .copy_client {

  }

  .copy_all {

  }
}
