





















































































































































































































































































































































































































































































































.header-calls {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__title {
    display: flex;
    align-items: center;
    gap: 10px;

    .select {
      width: 100px;
      max-width: 65px;

      ::v-deep .el-input__inner {
        border: none;
        background: inherit;
        height: 40px;
        font-weight: 700;
        font-family: inherit;
        padding: 0;
        color: #24387f;
        font-size: 16px;
      }

      ::v-deep .el-input {
        width: 70px;
        display: flex;
        align-items: center;
        gap: 10px;

      }

      ::v-deep .el-select__caret {
        font-size: 11px;
      }

      ::v-deep .el-input__suffix {
        display: none;
      }
    }

    .data-picker {
      ::v-deep .el-input__inner {
        height: 30px;
      }

      ::v-deep .el-input__icon {
        display: flex;
        align-items: center;
      }

      ::v-deep .el-range-separator {
        display: flex;
        align-items: center;
      }

      ::v-deep .el-range-input {
        width: 79%;
      }

      ::v-deep .el-date-editor--datetimerange {
        width: 350px;
      }
    }
  }

  .counts-call {
    & > div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 4px;

      .selectCountCalls {
        width: 20px;

        ::v-deep .el-input__inner {
          padding: 0 0 0 0px;
          height: 20px;
          text-align: center;
          font-weight: bold;
          color: #24387f;
          background: inherit;
          border: none;
        }

        ::v-deep .el-input__suffix-inner {
          display: none;
        }
      }
    }
  }

  p {
    margin: 0;
  }

  h4 {
    margin: 0;
    color: #252525;
    font-size: 15px;
  }

  margin-bottom: 20px;
}

.block-list {
  min-height: calc(100vh - 570px);
}

::v-deep .el-collapse-item__header {
  border-radius: 7px;
}

::v-deep .el-collapse-item__header {
  height: 40px;
}

::v-deep .el-collapse-item__content {
  padding-bottom: 10px;
  //border-top: solid 1px #b5b5b5;
}

.accordion-title {
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;


  ul {

    display: grid;
    grid-template-columns: 2.5rem  10rem 6rem 8rem 18rem 6rem 6rem 5rem 3rem;
    gap: 20px;

    li {
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;

      p {
        &:first-child {
          font-size: 0.5rem;
        }

        &:last-child {
          color: #555;
          font-size: 0.75rem;
          font-weight: normal;
        }
      }
    }
  }

  .index {
    display: flex;
    align-items: flex-end;

    p {
      font-weight: bold;
      color: #555;
      font-size: 1rem;
    }

  }

  * {
    margin: 0;
    padding: 0;
  }
}

.accordion-content {
  * {
    margin: 0;
    padding: 0;
  }

  border-radius: 5px;
  padding: 4px 10px;
  margin: 3px 20px !important;
  background: #dddddd;
  border: solid 1px #dddddd;

  &:hover {
    border: solid 1px #000;
  }

  ul {
    display: grid;
    grid-template-columns: 3rem 5.5rem 7rem 5.5rem 18rem 8rem 6rem 7rem 3rem;
    gap: 20px;
    cursor: pointer;


    li {
      &:nth-child(6), &:nth-child(7) {
        p:first-child {
          text-align: center;

        }


        p:last-child {
          text-align: center;
        }
      }

      p {
        color: #555;
        font-weight: bold;

        &:first-child {
          color: #fff;
          font-size: 0.5rem;
          text-shadow: 0 0 4px rgb(0 0 0 / 50%);


          span {
            font-weight: normal;
          }
        }

        .plus {
          color: green;
        }

        .minus {
          color: red;
        }

        &:last-child {
          margin-top: 3px;
          font-size: 0.75rem;
          font-weight: normal;
        }

        button {
          font-family: inherit;
          border: none;
          background: none;
          font-weight: normal;
          cursor: pointer;
          font-size: 0.75rem;
          color: #24387f;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

::v-deep .el-drawer__body {
  background: #fff;
}

.pagination-block {
  margin: 25px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-calls__title {
  ::v-deep .el-select {
    i {
      line-height: 0;
    }

    input {
      width: 160px;
      font-family: inherit;
      font-size: 12px;
    }
  }


  &:hover {
    input {
      border-color: #DCDFE6;

    }
  }
}

::v-deep .el-input__inner {
  height: 30px;
}


::v-deep .el-picker-panel, ::v-deep .el-date-picker ::v-deep .el-popper {
  position: absolute !important;
}


.hideDatePicker {
  position: absolute;
  opacity: 0;
  z-index: -1111;
}

::v-deep .el-select-group__title {
  font-weight: bold;
  font-size: 12px;
}

::v-deep .el-select-dropdown__item {
  font-size: 12px;
}

.no-calls {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-tooltip {
  background: inherit;
  color: #555;
  font-weight: normal;
  font-size: 11px;
  border: none;

  img {
    width: 13px;
    margin-left: 6px;
  }
}
