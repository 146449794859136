



















































































@import "./src/assets/css/project-variables";
  .progress-container {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    .progress {
      position: relative;
      height: 200px;
      width: 200px;
      background: conic-gradient($--color-danger 300deg, #ddd 0deg);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .center-circle {
        position: absolute;
        height: 180px;
        width: 180px;
        border-radius:50%;
        background: #ffffff;
        transition: all 0.1s ease-out;
      }
      &__value {
        position: relative;
        font-size: 1em;
        display: flex;
        font-weight: 600;
        transition: all 0.1s ease-out;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 7;
        line-height: .8;
      }
      &:hover {
        .progress {
          &__value {
            transform: scale(1.1);
          }

        }
        .center-circle {
          transform: scale(1.05);
        }
      }
    }
  }
