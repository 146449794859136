



















































































































































































































































































































































.dictionaries {
  margin: 0 20px 50px 20px;
  .transfer {
    text-align: center;
    .el-button {
      padding: 5px 10px;
      margin: 5px;
      i {
        font-size: 24px;
        font-weight: bold;
      }
    }
  }
  ::v-deep .el-table {
    &__cell {
      border-right: none;
      border-left: none;
    .el-color-picker {
      position: relative;
      top: 5px;
      &__mask,
      &__trigger{
        height: 30px;
        width: 30px;
      }
    }

    .el-input {
      &-number {
        width: 100px;
        &__decrease,
        &__increase {
          width: 30px;
          height: 28px;
          top: 5px;
          i {
            position: relative;
            top: -3px;
          }
        }
      }
      &__inner {
        height: 30px;
        line-height: 30px;
        padding: 0;
      }


    }
    }
  }
}
