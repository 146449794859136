



























































































































.sorted {
  display: grid;
  grid-template-columns: 230px 70px 140px 140px 70px 200px 130px 170px 170px;

  div {
    display: flex;
    gap: 20px;
    justify-content: center;

    i {
      cursor: pointer;
      font-size: 12px;
      color: #bebebe;

      &.active {
        color: #24387f;
      }
    }

    &:nth-child(3), &:nth-child(4), &:nth-child(6), &:nth-child(7), &:nth-child(8) {
      margin-left: 10px;
    }

    &:nth-child(4) {
      margin-left: 10px;
    }
  }


}
