






















































.info{
  transition: .3s;

  border-radius: 10px;
  border: solid 1px rgba(0, 0, 0, 0.13);
  width: 100%;
  min-height: 340px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);

  flex-direction: column;
  padding: 20px;
  background: #fff;
  h5{
    color: #555;
    margin-bottom: 5px;
  }
  &__general{
  margin-top: 10px;
    li{
      margin: 10px 0;
      border-bottom: dashed 1px black;
      display: flex;
      justify-content: space-between;
      span{
        z-index: 111;
        transform: translateY(8px);
        background: #fff;
        font-size: 12px;
        &:first-child{
          padding-right: 5px;
        }
        &:last-child{
          padding-left: 5px;
        }
      }
    }
  }
}
