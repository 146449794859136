































































































































































@import "./src/assets/css/project-variables";
  .title {
    margin-bottom: 20px;
    h2 {
      margin-right: 5px;
      margin-bottom: 0;
    }
  }
  .el-select {
    max-width: 100px;
    ::v-deep .el-input--suffix .el-input__inner {
      text-align: center;
    }
  }

  ::v-deep .el-collapse-item__header {
    display: block;
    line-height: normal;
    height: auto;
    position: relative;
    color: $--color-text-primary;
   .el-row {
      padding: 10px 0;
    }
    .el-collapse-item__arrow {
      position: absolute;
      z-index: 6;
      right: 0;
      top: 12px;
    }
  }
  .call-info {
    border: 1px solid $--color-primary-light-7;
    color: $--color-text-primary;
    padding: 5px 10px;
    flex-wrap: nowrap;
    margin-bottom: 5px;
    border-radius: 5px;
  }
