





































































.el-row {
  > .el-row {
    > .el-col {
      &:first-child {
        border-right: 1px solid #e1e1e1;
      }
      &:last-child {
        padding-left: 20px;
      }
    }

  }
  >h4 {
    margin-bottom: 10px;
  }
  h5 {
    margin-bottom: 10px;
    color: #000;
  }
  p {
    margin: 0 0 10px 0;
    color: #b5b5b5;
    font-size: 12px;
  }
  .data {
    font-size: 14px;
    .el-row {
      margin-bottom: 5px;
      > .el-col {
        &:first-child {
          color: #b5b5b5;
        }
      }
    }
  }
}
