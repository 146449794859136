




























































































































































































































$gray: #dadada;
$text: rgba(121, 118, 118, 0.98);
$controls: rgba(166, 165, 165, 0.98);
$background: #ececec;
$progress: #24387f;

.separator {
  height: 18px;
  width: 1px;
  background: rgba(187, 181, 181, 0.98);

}

.my-player {
  padding: 2px 10px;
  display: grid;
  border-radius: 8px;
  border: 1px solid #dadada;
  background: #fff;
  box-shadow: 0 0 4px 1px hsl(0deg 0% 83.5% / 6%);
  grid-template-columns: 65px 1fr 80px 30px 1px 30px 1px 30px;
  align-items: center;
  color: rgba(121,118,118,.98);
  font-size: 11px;
  gap: 7px;
  font-weight: 500;

  .controls {
    display: grid;
    grid-template-columns: 20px 1px 27px;
    gap: 5px;
    align-items: center;

    i {
      cursor: pointer;
      font-size: 17px;
      color: $controls;
      line-height: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__play {
      justify-self: center;
      align-self: center;
      width: 100%;

      i {
        font-size: 23px;
      }

      .el-player-stop {
        width: 100%;
        position: relative;
        display: flex;
        gap: 3px;

        &:before, &:after {
          content: '';
          width: 3px;
          height: 13px;
          bottom: 0;
          background: $controls;
        }
      }
    }

    &__stop {
      justify-self: center;
      align-self: center;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $controls;

      .el-player-sqrt {
        width: 14px;
        height: 14px;
        border: solid 2px $controls;
      }
    }

  }

  .timeline {
    cursor: pointer;
    width: 100%;
    height: 4px;
    background: #fff;
    display: flex;
    align-self: center;
    border-radius: 3px;
    box-shadow: inset 4px 4px 4px 0px #afafaf;

    &__progress {
      transition: .3s;
      width: 0%;
      height: 4px;
      background: $progress;
      position: relative;

      .dot {
        width: 14px;
        height: 14px;
        background: #fff;
        border-radius: 50%;
        justify-self: flex-end;
        align-self: center;
        position: absolute;
        right: -7px;
        top: calc(50% - 7px);
        box-shadow: 0 0 2px $text;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          width: 3px;
          height: 3px;
          border-radius: 50%;
          background: $progress;
        }
      }
    }
  }

  .time {
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      align-self: center;
      justify-self: center;
      line-height: 0;
    }
  }

  .volume {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: .3s;

    &__slide {
      opacity: 0;
      visibility: hidden;
      transition: .3s;
      position: absolute;
      height: 6px;
      width: 55px;
      background: #fff;
      border: solid 1px #b0afaf;
      bottom: 25px;
      cursor: pointer;
      display: flex;
      align-items: flex-end;
      border-radius: 3px;
      box-shadow: inset 4px 4px 4px 0px #afafaf;
      transform: rotate(-90deg) translateX(30px);

      .volume-progress {
        width: 100%;
        height: 100%;
        background: $progress;
        position: relative;
        border-radius: 3px;

        .dot {
          width: 14px;
          height: 14px;
          background: #fff;
          border-radius: 50%;
          justify-self: flex-end;
          align-self: center;
          position: absolute;
          right: -5px;
          top: -5px;
          box-shadow: 0 0 2px #000;
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            width: 3px;
            height: 3px;
            border-radius: 50%;
            background: $progress;
          }
        }
      }
    }

    &:hover {
      transition: .3s;

      .volume__slide {
        opacity: 1;
        transition: .3s;
        visibility: visible;
      }
    }


    img {
      cursor: pointer;
      align-self: center;
      justify-self: center;
      width: 18px;
    }
  }

  .speed {
    display: flex;
    align-items: center;
    justify-content: center;

    ::v-deep .el-select {
      background: inherit;
      width: 100%;

      .el-input__suffix {
        display: none;
      }

      input {
        font-weight: 500;
        width: 100%;
        border: none;
        background: inherit;
        color: $text;
        padding: 0;
        text-align: center;
        font-family: inherit;
      }
    }
  }

  .download {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $controls;

      i {
        align-self: center;
        font-size: 20px;
        line-height: 0;
        font-weight: 500;
      }
    }
  }
}

.error-file {
  margin-top: 40px;

  p {
    font-weight: bold;
    color: #555;
    text-align: center;
  }
}
