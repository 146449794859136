





































.el-row {
  h4 {
    text-align: center;
  }
  .progress {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
  > .el-row {
    > .el-col {
      font-size: 14px;
      &:first-child {
        color: #b5b5b5;
      }
      &:last-child {
        color: #1A2122;
        font-weight: 700;
      }
    }
  }
}
