









































































































































































































































































.add-category {
  &__headers {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    gap: 20px;
    border-bottom: solid 1px #bebebe;
    margin-bottom: 10px;

    h2 {
      margin-bottom: 5px;
    }
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    gap: 20px;
  }

  .btn-add {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .btn-add-row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 3px;
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 0;


    li {
      cursor: pointer;
      padding: 8px 7px;
      border: solid 1px #bebebe;
      line-height: 1;
      border-radius: 10px;
      transition: .3s;

      &:hover {
        border: solid 1px #24387f;
      }

      &.active {
        color: #fff;
        background: #24387f;
      }
    }
  }

  div {
    button {
      margin-top: 10px;
      cursor: pointer;
      background: #fff;
      border: solid 1px #24387f;
      border-radius: 50%;
      padding: 7px;
      height: 35px;
      width: 35px;

      i {
        color: #24387f;
        font-size: 16px;
        line-height: 1;
        font-weight: bold;
      }
    }

    .btn-save {
      margin-top: 20px;
      border-radius: 10px;
      font-size: 12px;
      width: 110px;
      transition: .3s;

      &:hover {
        background: #24387f;
        color: #fff;
      }
    }

    .btn-close {
      border: none;
      font-size: 10px;
      padding: 0;
      width: 40px;
      height: 20px;
      margin: 0;
      color: grey;
      transition: .3s;

      &:hover {
        color: red;
      }
    }
  }

  .add-inputs {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border: solid 1px black;
    border-right: none;
    border-left: none;
    padding: 10px 0;

    h5 {
      margin-bottom: 5px;
      text-align: center;
    }

    label {
      width: 100%;

      p {
        margin-bottom: 0;
        color: #555;
      }
    }

    input {
      width: 100%;
      font-family: inherit;
    }
  }

  &__sub-locations, &__locations, &__category {
    flex-direction: column;
  }

  &__locations {

  }

  &__sub-locations {

  }

  &__category {

  }
}

