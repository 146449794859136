















































































.select {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
  min-width: 300px;
  width: 100%;

  h6 {
    font-size: 15px;
    margin-bottom: 0;
    color: #555;
  }

  ::v-deep .el-input__inner {
    height: 30px;
  }

  ::v-deep .el-input__icon {
    display: flex;
    align-items: center;
  }

  ::v-deep .el-range-separator {
    display: flex;
    align-items: center;
  }

  ::v-deep .el-range-input {
    width: 79%;
  }

  ::v-deep .el-date-editor--datetimerange {
    width: 350px;
  }

  ::v-deep {
    .el-select {
      width: 100%;
    }
  }
}

