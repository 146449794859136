























































































































































































.item {
  margin: 7px 0;
  background: #f4f7fd;
  box-shadow: 1px 1px 2px 1px #f4f7fd;
  border-radius: 4px;
  cursor: pointer;
  border-bottom: solid 1px #f4f7fd;
  transition: .3s;
  overflow: hidden;
  display: grid;
  grid-template-columns: 230px 70px 140px 140px 70px 200px 130px 170px 170px;

  div {
    position: relative;
  }

  &:hover {
    border-color: grey;
  }

  .cont {
    position: relative;
    font-size: 10px;
    font-family: inherit;
    font-weight: normal;
    color: #555;
    padding: 15px 10px 5px;

    p {
      font-size: 8px;
      margin: 0;
      position: absolute;
      top: 2px;
      white-space: nowrap;

    }

    .row {
      display: flex;
      gap: 5px;
    }

  }

  .calls-all, .calls-day {
    p {
      width: 100%;
      margin-left: 10px;
    }

    .row {
      display: grid;
      grid-template-columns: 55px 1px 55px;

      span:first-child {
        justify-self: flex-end;
      }

      span:last-child {
        justify-self: flex-start;
      }
    }
  }

  .talk-time {
    p {
      width: 100%;
      margin-left: 10px;
    }

    .row {
      display: grid;
      grid-template-columns: 87px 1px 87px;

      span:first-child {
        justify-self: flex-end;
      }

      span:last-child {
        justify-self: flex-start;
      }
    }
  }

  .positive-weight, .negative-weight {
    p {
      width: 100%;
      margin-left: 5px;
    }

    .row {
      display: grid;
      grid-template-columns: 72px 1px 72px;

      span:first-child {
        justify-self: flex-end;
      }

      span:last-child {
        justify-self: flex-start;
      }
    }
  }

  .rate {
    text-align: center;
  }

  .negative-weight {
    i {
      color: red;
      font-style: normal;
    }
  }

  .positive-weight {
    i {
      color: green;
      font-style: normal;
    }
  }
}

.separator {
  display: inline-block;
  font-size: 12px;
  width: 1px;
  height: 15px;
  background: #bebebe;
}

