


































































































































































































































.user {
  border: 1px solid #eee;
  .user-scroll {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .el-select{
    width: 100%;
  }
  ::v-deep .el-form {
    &-item {
      margin: 10px;
      position: relative;
      &__content {
        line-height: 1;
      }
      .el-icon-edit {
        position: absolute;
        top: 0;
        right: 10px;
      }
      &__label{
        width: 100%;
        text-align: left;
        font-weight: bold;
        font-size: 10px;
        line-height: 1;
      }
      .el-input {
        font-size: 12px;

        &__inner {
          border-radius: 4px;
          height: 30px;

        }
        &__suffix {
          .el-icon-arrow-up {
            &:before {
              position: relative;
              top: -5px;
            }

          }
        }
      }
      .categories {
        height: 45px;
        overflow-y: clip;
        span {
          padding-right: 5px;
          font-size: 10px;
          color: #555555;
        }
      }
      .el-button {
        &--primary {
          padding: 5px 10px;
          font-size: 12px;
        }
      }
    }
  }

  position: relative;
  .el-icon-delete {
    position: absolute;
    color: #555555;
    top: 15px;
    right: 10px;
    font-size: 14px;
  }
}
