












































































































.category {
  flex-basis: 75%;

  h6 {
    font-size: 15px;
    margin-bottom: 10px;
    color: #555;
  }

  div {
    display: flex;
    align-items: center;
    line-height: 1;
    gap: 10px;

    p {
      margin: 0;
      font-weight: bold;
      color: #555;

    }
  }

}

