










































































































.authBlock {
  font-family: 'Gotham Pro';
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    max-width: 1200px;
    width: 100%;
    height: 400px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 6px 0px #00000054;
    display: grid;
    grid-template-columns: 200px 500px 500px;
    padding: 0;

    .left-block {
      .logo {
        margin-top: 20px;

        img {
          border-radius: 50%;
          max-width: 130px;
          width: 100%;
        }
      }

      .tabs {
        margin-top: 30px;
        display: grid;
        gap: 10px;

        &__item {
          cursor: pointer;
          color: #dcdcdc;
          padding: 5px 0;
          display: grid;
          font-size: 12px;
          text-align: center;
          font-weight: normal;
          border-left: solid 4px inherit;
          gap: 2px;

          i {
            font-size: 20px;
          }
        }

        &__item.active {
          color: #555;
          border-left: solid 4px #24387f;
        }
      }
    }

    .welcomeBlock {
      position: relative;

      &__sqrt {
        position: absolute;
        background: #24387f;
        width: 100%;
        height: 120%;
        top: -40px;
        box-shadow: 1px 4px 4px 1px #0000005e;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h1 {
          font-weight: bold;
          text-shadow: 2px 0px 9px #00000063;
          max-width: 300px;
          width: 100%;
          text-align: start;
          color: #fff;
          font-family: inherit !important;
          margin-bottom: 20px;
          transform: translate(-20px, -40px);
        }

        .stupid-text {
          position: absolute;
          bottom: 20px;
          right: 20px;
          text-align: end;
          margin-bottom: 0;
          font-size: 22px;
          font-family: 'Caveat', cursive;
          font-weight: bold;
        }

        p {
          max-width: 300px;
          width: 100%;
          text-align: start;
          color: #dadada;
        }
      }
    }

    .right-block {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;

      .login {
        max-width: 300px;

        h5 {
          margin-bottom: 30px;
          text-align: start;
        }

        &__inputs {
          button {
            border-radius: 50%;
            padding: 10px;
            border: none;
            background: #24387f;
            color: #fff;
            font-size: 20px;
            cursor: pointer;
            line-height: 1;
            overflow: hidden;
            transition: translateX .3s;

            &:hover {
              background: #2b51b9;

              i {
                animation: arrow-right 1s linear infinite;
              }

            }

            @keyframes arrow-right {
              0% {
                transform: translateX(0);
              }
              25% {
                transform: translateX(3px)
              }
              50% {
                transform: translateX(0px)
              }
              75% {
                transform: translateX(-3px)
              }
              100% {
                transform: translateX(0px);
              }
            }
          }

          ::v-deep {
            .el-input {
              margin-bottom: 20px;

              input {
                font-family: inherit;

                &::placeholder {
                  font-size: 13px;
                  line-height: 1;
                }
              }
            }
          }
        }
      }
    }
  }
}

