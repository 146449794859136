






















































































































.title {
  position: absolute;
  top: 25px;
  font-size: 0.7em;
  text-align: center;
  width: 100%;
  transition: all 0.1s ease-out;
}
.circle:hover span .title {
  opacity: 0;
}
.circle .bar,
.circle .fill {
  position: absolute;
  width: 0.8em;
  height: 0.8em;
  clip: rect(0em, 0.5em, 1em, 0em);
  border-radius: 50%;
  transform: rotate(0deg);
}

.circle {
  position: relative;
  font-size: 120px;
  width: 1.003em;
  height: 1.003em;
  left: -0.07px;
  border-radius: 50%;
  margin: 0 0.1em 0.1em 0;
  background-color: #ededed;
}
.circle *,
.circle *:before,
.circle *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.circle > span {
  position: absolute;
  left: 0.05em;
  font-weight: 500;
  z-index: 1;
  top: 0;
  width: 5em;
  line-height: 5em;
  font-size: 0.2em;
  color: #cccccc;
  display: block;
  text-align: center;
  white-space: nowrap;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
.circle:after {
  position: absolute;
  top: 0.1em;
  left: 0.1em;
  display: block;
  content: " ";
  border-radius: 50%;
  background-color: #f5f5f5;
  width: 0.8em;
  height: 0.8em;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
}
.circle .slice {
  position: absolute;
  width: 1em;
  height: 1em;
  clip: rect(0em, 1em, 1em, 0.5em);
}
.circle:hover {
  cursor: default;
}
.circle:hover > span {
  width: 3.33em;
  line-height: 3.33em;
  font-size: 0.3em;
}
.circle:hover:after {
  top: 0.08em;
  left: 0.08em;
  width: 0.84em;
  height: 0.84em;
}
.circle.dark {
  background-color: #777777;
}
.circle.dark > span {
  color: #777777;
}
.circle.dark:after {
  background-color: #666666;
}



