








































































































































h6 {
  font-size: 15px;
  margin-bottom: 0;
  color: #555;
}

::v-deep .el-input__inner {
  height: 30px;
}

::v-deep .el-input__icon {
  display: flex;
  align-items: center;
}

::v-deep .el-range-separator {
  display: flex;
  align-items: center;
}

::v-deep .el-range-input {
  width: 79%;
}

::v-deep .el-cascader {
  min-width: 250px;
  width: 100%;
}

::v-deep {
  .el-select {
    width: 100%;
  }
}

