



























































































































































































































.container {
  padding: 15px;
  min-height: 85vh;


  .title {
    margin-bottom: 30px;

    h4 {
      margin-bottom: 0px;
      font-size: 18px;
      font-weight: 700;
      color: #555;

      span {
        font-weight: normal;
        font-size: 14px;
      }
    }
  }

  .content {
    padding: 15px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 12%), 0 0 6px rgb(0 0 0 / 4%);
    border-radius: 10px;
    background: #fff;
    min-height: 75vh;

    .select-block {
      display: flex;
      //align-items: center;
      justify-content: space-between;
      gap: 10px;
      .wrapp{
        display: flex ;
      }

    }
  }
}

::v-deep {
  .el-input__inner {
    height: 30px;
  }

  .el-input__suffix {
    i {
      line-height: 1;
    }
  }
}
