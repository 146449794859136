



































































.info {
  margin: 15px 20px;

  &__title {
    h4 {
      margin-bottom: 40px;
      font-size: 13px;
      display: flex;
      gap: 10px;
      align-items: center;
      color: #000;
      font-weight: 450;
    }

  }

  ul {
    li {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      align-items: center;
      &:first-child{
        span {
          &:nth-child(2) {
            position: relative;
            &:before {
              position: absolute;
              content:'-';
              right: -5px;
            }
          }
        }
      }
      span {
        &:first-child {
          font-weight: 450;
          font-size: 11px;
          color: #252525;
          padding-right: 5px;
        }
        &:last-child {
          text-align: right;
        }
        font-size: 10px;
        color: #252525;
        font-weight: normal;
      }
    }
  }
}
