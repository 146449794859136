





































































































































































@import "./src/assets/css/project-variables";

.header {
  z-index: 65;
  background: #fff;
  max-height: 125px;
  position: sticky;
  top: 0;
  text-align: center;
  margin-left: auto;
  padding: 15px;
  border-bottom: 1px solid #ddd;
  width: calc(100% - 110px) !important;

  &__logo {
    display: flex;
    //background: $--color-primary;
    border-radius: 50%;
    width: 65px;
    height: 65px;
    margin: 20px auto 0 auto;
    font-size: 27px;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    color: #fff;

    img {
      max-width: 100px;

    }
  }

  &__account-name {
    color: $--color-primary;
  }

  &__search {
    width: 70%;
    margin-right: auto;

    .el-icon-search {
      color: #ddd;
      font-size: 24px;
      position: relative;
      top: 7px;
      z-index: 2;
    }

    .el-input {
      left: -10px;

      input {
        border: none;
        font-size: 16px !important;
        font-weight: bold;
      }
    }

  }

  &__account {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin: 0 16px 0 0;

    &-inner {
      padding-right: 10px;
    }

    &-bell {
      position: relative;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;

      .attention {
        position: absolute;
        top: -4px;
        right: 0;
        width: 10px;
        height: 10px;
        flex-shrink: 0;
        background-color: $--color-secondary-red;
        border: 2px solid $--color-white;
        border-radius: 50%;
      }

      &.active {
        cursor: pointer;
      }
    }

    &-avatar {
      width: 35px;
      height: 35px;
      margin: 0 16px 0 0;
      border-radius: 50%;

      img {
        width: 100%;
        border-radius: 50%;
      }
    }

    &-name {
      font-size: 14px;
      font-weight: 700;
    }

    &-position {
      font-size: 12px;
      color: $--color-text-regular-4;
    }

    &-link {
      font-size: 14px;
      color: $--color-primary;
    }
  }

  &__logout {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      display: block;
      height: auto;

      path {
        transition: all .4s;
      }
    }

    &:hover {

      svg {

        path {
          stroke: $--color-primary;
        }
      }
    }
  }


}

.el-aside {
  z-index: 66;
  width: 110px !important;
  height: 100vh !important;
  padding: 0;
  margin: 0;
  max-width: 100%;
  min-height: 92vh;
  position: fixed;
  overflow-x: hidden;
  top: 0;
  box-shadow: 1px 1px 2px #ddd;
  transition: all 300ms linear;
}

.el-main {
  display: block;
  flex: none;
  overflow: auto;
  box-sizing: border-box;
  background: $--background-color-base;
  height: calc(100vh - 125px);
  padding: 20px;
  margin-left: auto;
  width: calc(100% - 110px) !IMPORTANT;
}

.header__account-avatar, .header__account-inner {
  cursor: pointer;
}
