






















::v-deep{
  .el-steps{
    font-family: inherit !important;
    font-weight: normal;
  }
  .el-step__title{
    font-family: inherit !important;
    font-size: 12px ;
    line-height: 3;
    font-weight: bold;
  }
}

