




































































































































.vats-config {
  min-height: 66vh;
  display: flex;
  justify-content: flex-start;

  .wrapper {
    padding: 15px;
    border-radius: 5px;
    max-width: 500px;

    width: 100%;
    border: solid 1px #E4E7ED;

    .vats-on {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h4 {
        margin-bottom: 0;
        color: #bebebe;
        transition: color .3s;

        &.active {
          color: #000;
        }
      }
    }

    .hr {
      margin: 5px 0;
      width: 100%;
      height: 1px;
      background: #E4E7ED;
    }

    .form {
      margin-top: 10px;

      div {
        margin-bottom: 3px;
      }

      label {
        &:first-child {
          margin-top: 0;
        }

        p {
          margin-bottom: 0;
          font-size: 12px;
          color: #555;
        }

        &::v-deep {
          .el-input, .el-select, .el-input-number {
            width: 100% !important;
          }

          input, select {
            width: 100% !important;
            border-radius: 5px;
            text-align: start;
          }
        }
      }

      .btn-block {
        margin-top: 15px;
        display: flex;
        justify-content: center;
        gap: 20px;

        button {
          cursor: pointer;
          background: unset;
          padding: 10px 15px;
          border: none;
          border-radius: 5px;
          color: #fff;
          font-size: 12px;
          font-weight: bold;

          &:first-child {
            background: #dc0000;
          }

          &:last-child {
            background: #24387f;
          }
        }
      }
    }
  }
}
