






























































































































































































































































































































































































.add-location {
  text-align: right;
  margin-left: 5px;
  margin-bottom: 10px;
}

.location {
  margin: 0 0 10px 0;

  h1 {
    margin-left: 10px;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .el-icon-refresh {
    transition: all .1s ease-out;

    &:hover, :focus {
      color: #363636;
      transform: scale(1.1);
    }
  }


  h4 {
    margin-bottom: 10px;
    font-size: 19px;
  }

  .general-info {
    .el-col {
      background: #fff;
      padding: 30px;
      border-radius: 5px;
      margin: 10px;
      box-shadow: 0 5px 14px #d3d8e7, 0 4px 6px #edf1fc;
    }
  }

  .trend {
    background: #fff;
    padding: 30px;
    border-radius: 5px;
    margin: 10px;
    box-shadow: 0 5px 14px #d3d8e7, 0 4px 6px #edf1fc;

    &__description {
      font-size: 14px;
      color: #b5b5b5;

      i {
        font-size: 27px;
        color: #000;
        cursor: pointer;
        position: absolute;
        right: 0;

        .el-date-editor .el-range-separator {
          width: 8%;
        }
      }

      .el-col {
        > span {
          &:nth-child(odd) {
            color: #000;
            font-weight: 900;
          }

          &:nth-child(even) {
            font-size: 16px;
          }

          &.color {
            display: inline-block;
            margin-right: 5px;
            width: 12px;
            height: 12px;
            top: 5px;
            left: -15px;
          }
        }

      }

    }

  }


}
