


















































































































































































































.location-form-container {
  h4 {
    margin-bottom: 20px;
  }

  .form-fields {
    display: flex;
    flex-direction: column;
    width: 50%;
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 20px;

    > .el-row {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      > .el-col {
        &:first-child {
          padding-right: 10px;
          text-align: right;
        }
      }
    }
  }

  .command-buttons {
    margin-bottom: 25px;
  }
}

