















.chart{
  margin-top: 20px;
  &__title{
    margin-bottom: 10px;
    h3{
      color: #555;
      font-size: 15px;
    }
  }
}

