
































































































































@import "./src/assets/css/project-variables";
.v-modal {
  z-index: 9999!important;
}
.el-row {
  >h4, h5 {
    margin-bottom: 10px;
  }
  h5 {
    color: $--color-danger;
  }
  i.el-icon-circle-plus-outline, i.el-icon-refresh {
    position: absolute;
    top: 5px;
    right: 0;
    color: #b5b5b5;
    transition: all 0.1s ease-out;
    font-weight: bold;
    font-size: 16px;
    &:hover {
      transform: scale(1.1);
    }
  }
  i.el-icon-refresh {
      right: auto;
      left: -20px;
      top: -20px;
  }
  .sub-location {
    margin: 0 0 10px 0;
    span {
      cursor: pointer;
      color: $--color-text-primary;
    }
  }
  > .el-row {
    > p {
      color: #b5b5b5;
      margin-left: 15px;
      font-size: 16px;
      cursor: pointer;
    }
  }
}
