



































.user-row-inactive {
  color: #cecece;
}

.page {

  &__head {

    &-right {
      display: flex;
      align-items: center;
    }
  }
}

.ellipsis-menu-slot {

  > span:not(:last-of-type) {
    margin-bottom: 16px;
  }
}

