
































































































































.login .el-card {
  //margin-top:300px;
  width: 340px;
  //display: flex;
  justify-content: center;
}
