












































































.item {

  background: #fff;
  border: solid 1px #8080802e;
  box-shadow: 2px 2px 7px 0 #8080802e;
  border-radius: 5px;

  .title {
    padding: 8px 8px;

    h6 {
      color: #555;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 0;
    }
  }

  .color-line {
    height: 4px;
    width: 100%;
  }

  .graph {
    padding: 10px 8px;

    .graph-item {
      display: grid;

      p {
        color: #777777;
        font-size: 10px;
        margin-bottom: 0;

      }

      .row {
        margin: 5px 0;
        display: flex;
        align-items: center;
        gap: 5px;


        .filled {

          height: 4px;
          background: rgba(0, 0, 255, 0.7);
          border-radius: 5px;
          transition: width .3s;
          width: 0;
        }

        p {
          line-height: 0;
          margin-bottom: 0;
        }
      }
    }

  }
}
