


































































































@import "src/assets/css/project-variables";
li {

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 0px 0 10px;
  position: relative;

  .show-call-dialog {
    color: $--color-primary;
    cursor: pointer;
    font-size: 11px;
  }

  .el-icon-sort {
    position: absolute;
    top: 5px;
    left: -10px;
    transform: rotate(90deg);
    color: red;
    font-weight: 450;
  }


  span {
    font-weight: normal;
  }

  div {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #555;
    line-height: 1.3;
    font-size: 12px;
    font-family: inherit;
  }

  > span:last-child {
    font-weight: normal;
  }

  .dictionary-color {

    width: 35px;
    height: 15px;
    background: gray;
    border-radius: 4px;
    display: flex;
    justify-content: center;

    span {
      text-align: center;
      justify-content: center;
      color: #fff;
      font-style: normal;
    }
  }

  ::v-deep .category-calls .popover-button {
    padding: 0 2px;
  }
}

