























































































































































































































































@import "./src/assets/css/project-variables";
.el-menu {
  border: none;
  width: 100%;
  margin-top: 25px;
  .el-menu-item {
    height: auto;
    padding:12px 3px 12px 0!important;
    border-left: 5px solid transparent;
    box-sizing: border-box;
    &:hover, &:focus, &.is-active{
      background-color: #eaf0fd;
      border-color: $--color-primary;

    }

    p {
      text-align: center;
      margin: 0;
      line-height: 1;
      font-size: 10px;
      color: #696969;
      line-height: 1.3;
      &.menu-icon {
        width: 40px;
        height: 40px;
        background-size: contain;
        margin: 0 auto 5px auto;
      }
      &.location-icon {

      }
      &.dictionary-and-checklist-icon {

      }
      &.search-icon {

      }
      &.violation-icon {

      }
      &.trend-icon {

      }
      &.constructor-icon {

      }
    }


  }
}

