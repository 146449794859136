









































































@import "src/assets/css/project-variables";
.list{

  &__item {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    font-size: 11px;
    align-items: center;
    font-weight: normal;
    .show-call-dialog {
      color: $--color-primary;
      cursor: pointer;
    }
    & > *:nth-child(2){
      text-align: center;
    }
    &:first-child, &:nth-child(2) {
      grid-template-columns: 2fr 1fr;
      & > *:nth-child(2){
        text-align: right;
      }
    }
    & > *:first-child{
      font-weight: bold;
      padding-left: 5px;
    }
    & > *:last-child{
      text-align: right;

    }

  }

}

