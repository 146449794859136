




































































































































































::v-deep .el-loading-spinner {
  .circular {
    width: 15px;
    height: 15px;
    top: 14px !IMPORTANT;
    position: relative;
  }
}
::v-deep .el-loading-parent{
  &--relative {
    width: 20px;
  }
}
.item {
  max-width: 330px;
  background: #fff;
  padding-bottom: 75px;
  position: relative;
  border-radius: 5px;
  user-select: none;
  border: solid 1px #8080802e;
  box-shadow: 2px 2px 7px 0 #8080802e;
  @media (max-width: 1700px) {
    & {
      max-width: 330px;
    }
  }
  @media (max-width: 1500px) {
    & {
      max-width: 400px;
    }
  }
  @media (max-width: 1200px) {
    & {
      max-width: unset;

      p {
        max-width: 480px !important;
      }
    }
  }
  .first {
    margin: 0 5px;
  }

  p {
    word-wrap: break-word;
    max-width: 320px;
    max-height: 200px;
    overflow: auto;
    margin: 0;
  }

  ::v-deep .el-tooltip {
    &__popper.is-dark {
      font-size: 40px;
      background: red;
    }

  }

  .item-header {
    padding: 10px 10px 5px;
    display: flex;
    justify-content: space-between;

    .btns-actions {
      display: flex;

      & > i {
        margin-right: 5px;
        height: 14px;
      }

      & > span {
        line-height: 1;
      }

      i {

        color: #8a8a8a;
      }

    }

    h4 {
      margin: 0;
      color: #555;
      font-weight: 500;
      line-height: 1.3;
      font-size: 12px;
    }

    button {
      cursor: pointer;
      padding: 5px;
      border: none;
      background: none;
      i {
        color: #bababa;
      }
    }
  }

  .dict-description {
    padding: 0 10px;
    ul{

      padding: 5px 10px 0 12px;

      max-height: 200px;
      overflow: auto;
      list-style: square;
      li{

        word-wrap: break-word;
        list-style: square;
        color: #8a8a8a;
        font-size: 11px;
      }
    }

    p {
      font-size: 11px;
      color: #8a8a8a;
      padding: 0 10px 0 0;
      span:first-child {
        font-weight: bold;
      }
    }
  }

  .footer-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    background: #ececec;
  }

  .item-footer {
    position: relative;


    &__performed {
      position: absolute;
      display: flex;
      top: -9px;
      right: 10px;
      border-radius: 2px;
      font-size: 12px;
      text-align: center;
      width: 85px;
      height: 20px;
      justify-content: flex-end;

      > span {
        display: inline-block;

        height: 100%;
        line-height: 1.7;
        border-radius: 4px;
        width: 80px;
        margin-right: 15px;

        color: #fff;

        &:last-child {
          background: red;
        }

        &:first-child {


          background: green;
        }
      }
    }

    &__content {
      padding: 15px 10px 10px;

      ul {
        margin: 0;

        li {
          font-size: 11px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #8a8a8a;
        }
      }


    }
  }

  .client-span {
    color: red;
  }

  .colleague-span {
    color: #24387f;
  }
}


