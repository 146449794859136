

























































































































.list {
  margin-top: 20px;

  &__title {
    margin-bottom: 10px;

    h3 {
      color: #555;
      font-size: 15px;
      margin-bottom: 0;
    }
  }

  ul {
    margin-bottom: 0;
    min-height: 200px;
    padding: 0 10px 0 5px;
  }

}
.progress{
  ::v-deep{
    .el-progress-bar__innerText{
      color: #fff !important;
      font-size: 10px;
      transform: translateY(-1px) !important;
    }
  }
}



