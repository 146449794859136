

























































































.container{

  background-position: left;
  padding: 15px;

  min-height: 85vh;
  .title{
    margin-bottom: 30px;
    h4{
      font-family: inherit;
      margin-bottom: 10px;
      font-size: 43px;
      font-weight: 700;
      color: #878787;
      text-align: end;
      line-height: 1.3;

    }
  }
  .menu-wrapper{
    padding: 20px;
    background-image: url("./../assets/img/icons/analytic/bg.png");
    background-repeat: no-repeat;
    background-position-y: 0px;
    background-position-x: -20px;
    min-height: 440px;
    display: flex;
    justify-content: center;
    background-size:27%;

  }
  .menu{
    margin-left: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr ;
    grid-template-rows: 1fr 1fr;
    gap:15px;
    max-width: 1200px;
    //margin: 0 auto;
    &__item{
      min-width: 203px;
      background: #fff;
      cursor: pointer;
      padding: 25px;
      box-shadow: 0 2px 4px rgb(0 0 0 / 12%), 0 0 6px rgb(0 0 0 / 4%);
      border-radius: 10px;
      pointer-events: none;
      opacity: 0.4;
      &:nth-child(1){
        grid-column-start: 1;
        grid-row-start: 1;
        pointer-events: unset;
        opacity: 1;
      }
      &:nth-child(2){
        grid-column-start: 2;
        grid-row-start: 1;
        pointer-events: unset;
        opacity: 1;
      }
      &:nth-child(3){
        grid-column-start: 3;
        grid-row-start: 1;
        pointer-events: unset;
        opacity: 1;
      }
      &:nth-child(4){
        grid-column-start: 2;
        grid-row-start: 2;
        pointer-events: unset;
        opacity: 1;
      }
      &:nth-child(5){
        grid-column-start: 3;
        grid-row-start: 2;
      }
      &:nth-child(6){
        grid-column-start: 4;
        grid-row-start: 2;
      }
      &:nth-child(7){
        grid-column-start: 5;
        grid-row-start: 2;
      }
      &:nth-child(8){
        grid-column-start: 6;
        grid-row-start: 2;
      }

      img{
        margin-top: 10px;
        width: 100%;
        max-width: 27px;
      }

      h5{
        margin-top: 40px;
        color: #1f1f1f;
        text-align: start;
        font-size: 16px;
        line-height: 1;
        margin-bottom: 3px;
        span{

          font-weight: normal;
          font-size: 13px;
          color: #393939;
        }
      }
      p{
        font-weight: normal;
        font-size: 13px;
        color: #393939;
        margin-bottom: 0;
      }
    }
  }
}

