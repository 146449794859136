
























































































































































































.dictionaries-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

.add {
  text-align: right;
  margin-bottom: 10px;
}
.top-block {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h4{
    margin: 0;
  }
}
