
















































































































































































.container {
  padding: 15px;
  min-height: 85vh;
  border-right: 10px;

  .title {
    margin-bottom: 30px;

    h4 {
      margin-bottom: 0px;
      font-size: 18px;
      font-weight: 700;
      color: #555;

      span {
        font-weight: normal;
        font-size: 14px;
      }
    }
  }

  .content {
    padding: 15px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 12%), 0 0 6px rgb(0 0 0 / 4%);
    border-radius: 10px;
    background: #fff;
    min-height: 75vh;

    .result {
      border-top: solid 1px #DCDFE6;
      display: grid;
      grid-template-columns: 1fr;
      justify-content: center;
      gap: 20px;
      margin-top: 20px;
    }
  }
}

.select-block {
  display: flex;
  align-items: flex-start;
  gap: 20px;

}

.wrap {
  display: flex;
  gap: 20px;
  justify-content: space-between;

}

::v-deep {
  .el-input__suffix {
    display: none;
  }

  .el-input__inner {
    height: 30px;
  }

  .el-input__suffix {
    i {
      line-height: 1;
    }
  }
}
