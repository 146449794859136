
















































.el-card {
  padding: 2rem;
  position: relative;
}

.el-icon-delete {
  position: absolute;
  top: -25px;
  right: -26px;
  cursor: pointer;
}

