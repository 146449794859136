












































































































































































































































































































































































































































































.dictionaries-transfer {
  margin: 0 20px 50px 20px;

  h4 {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .search {
    margin-bottom: 10px;

    ::v-deep .el-input__inner {
      width: 100%;
      height: 30px;
    }
  }

  .info {
    margin-bottom: 10px;

    div {
      &:first-child {
        margin-bottom: 10px;
      }

      &:nth-child(2), &:nth-child(3) {
        display: grid;
        grid-template-columns: 120px 70px;
        margin-left: 20px;

        span {
          &:last-child {
            padding-left: 20px;

            i {
              font-weight: bold;
              font-size: 13px;
              color: #252525;
            }
          }
        }
      }
    }
  }

  .available, .selected {
    max-height: 30vh;
    padding: 0 20px 0 0;
    overflow-y: auto;

  }

  .transfer {
    text-align: center;

    .el-button {
      padding: 0 10px;
      margin: 5px;

      i {
        font-size: 12px;
        font-weight: bold;
      }
    }
  }

  ::v-deep .el-table {
    position: relative;

    &__header {
      &-wrapper {

      }
    }

    &__cell {
      border-right: none;
      border-left: none;
      padding: 2px 0;
      color: #252525;
      font-size: 11px;

      .cell {
        white-space: nowrap;
      }

      .el-color-picker {
        position: relative;
        top: 5px;

        &__mask,
        &__trigger {
          height: 25px;
          width: 25px;
        }
      }

      .el-input {
        &-number {
          width: 100px;

          &__decrease,
          &__increase {
            width: 20px;
            height: 18px;
            top: 9px;

            i {
              position: relative;
              top: -8px;
            }
          }
        }

        &__inner {
          height: 20px;
          line-height: 20px;
          padding: 0;
        }
      }

      .el-switch {
        &__core {
          height: 18px;

          &:after {
            top: 0;
          }
        }
      }

    }
  }
}
