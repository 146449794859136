

































































































































































































































































































































































































































































































































@import "../assets/css/project-variables";
.filters {
  text-align: right;
  i {
    cursor: pointer;
    font-size: 32px;
    color: $--button-default-color;
  }
}

.filters-form {
   > div {
     display: grid;
     grid-template-columns: 1fr 1fr;
     > .el-select {
       width: 350px;;
     }
   }

}

.category-select {
  ::v-deep {
  .el-input__inner {
    height: 30px;
  }

    .el-select__caret{
      line-height: 0;
    }

  //.el-input__suffix {
  //  top: 4px;
  //}
  //
  //.is-focus {
  //  .el-input__suffix {
  //    top: -4px;
  //  }
  //}
}
}


::v-deep .category-select.el-select {
  width: 50%;

  .el-input {
    background: #f5f7fd;

    input {
      background: #fff;
    }

    &__inner {
      background-color: transparent;
    }

  }
}

.category {
  h1 {
    margin-bottom: 0px;
    line-height: 1;
    font-size: 20px;
  }

  margin: 20px;

  &-entities {
    display: grid;
    grid-template-columns: 1fr 1.7fr 1fr;
    column-gap: 15px;
    padding: 3px;

    &__dictionaries,
    &__general-info,
    &__checklists {
      background: #fff;
      border-right: 4px;
      box-shadow: 0 2px 4px rgb(0 0 0 / 12%), 0 0 6px rgb(0 0 0 / 4%);
      border-radius: 10px;
    }

  }


  .collapse {
    border: none;

    ::v-deep .el-collapse {
      border: none;

      &-item__header,
      &-item__wrap {
        background-color: transparent;
        color: #252525;
        font-weight: bold;
        border: none
      }

      &-item__content {
        padding: 0;
      }
    }
  }

  ::v-deep .el-breadcrumb {
    margin-bottom: 20px;
    line-height: 1.5;
  }

}

.call-list-block {
  margin-top: 20px;
}

.hr-separator {
  border-top: 1px solid #EBEEF5;
  border-bottom: 1px solid #EBEEF5;
  margin-top: 20px;
}

.checklist-work {
  background: #fff;
  padding: 15px;
  max-width: 570px;
  width: 100%;
}

.category-select {
  background: #fff;
}

.category-control {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.counts-call {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;

  p {
    margin-bottom: 0;
  }
}
