





















































































































































































































@import "./src/assets/css/project-variables";

.header__account-bell {
  position: relative;
  margin-right: 0;
}

.notifications {
  padding: 0;
  border-right: 5px;
  box-shadow: 1px 0 30px 7px rgba(20, 33, 64, .06);

  &__header {
    padding: 24px 24px 16px;
    border-bottom: 1px solid $--color-divider;

    > span {
      font-size: 12px;
      padding-top: 3px;
    }

    > label {
      display: flex;
      align-items: center;
      margin-left: auto;

      .el-switch {
        line-height: 1.5;
        margin-right: 8px;

        + span {
          font-size: 14px;
          color: $--color-text-regular-4;
        }

        &.is-checked {

          + span {
            color: $--color-text-regular;
          }
        }
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    padding: 20px 24px 16px;
    border-top: 1px solid $--color-divider;
  }

  @media only screen and (max-width: 1480px) {

    &__header,
    &__footer {
      padding: 12px 16px;
    }
  }
}

.notification-counter {
  cursor: pointer;

  .el-badge__content {
    position: absolute;
    background-color: #E21A1A;
    height: 20px;
    line-height: 18px;
    font-size: 12px;
    top: 5px;
    margin-right: 16px;
  }
}

.notification-list {
  max-height: 400px;
  overflow: auto;
  list-style-type: none;
  padding: 0;
  margin: 0;

  .notification-list-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 24px;
    border-bottom: 1px solid $--color-divider;
    cursor: pointer;
    transition: background-color .4s;

    &__date {
      margin-bottom: 4px;
      font-size: 12px;
      color: $--color-text-regular-4;
    }

    &__text {
      margin-bottom: 8px;
      text-align: left;
    }

    &__btn {

      i {
        font-size: 14px;
      }
    }

    &:hover {
      background-color: $--color-divider-4;
    }

    &:last-child {
      border-bottom: none;
    }

    &.seen {

      .notification-list-item__text {
        color: $--color-text-regular-4;
      }
    }
  }

  @media only screen and (max-width: 1480px) {

    .notification-list-item {
      padding: 12px 16px;

      &__text {
        font-size: 12px;
      }
    }
  }
}

