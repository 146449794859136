









































































































































.item {

  border-radius: 5px;
  user-select: none;
  border: solid 1px #8080802e;
  box-shadow: 2px 2px 7px 0px #8080802e;

  .item-header {
    padding: 10px 10px 5px;
    display: flex;
    justify-content: space-between;

    h4 {
      margin: 0;
      color: #555;
      font-weight: 500;
      line-height: 1.3;
      //white-space: nowrap; /* Запрещаем перенос строк */
      //overflow: hidden; /* Обрезаем все, что не помещается в область */
      //text-overflow: ellipsis;
      font-size: 13px;
    }

    button {
      cursor: pointer;
      padding: 5px;
      border: none;
      background: none;

      i {
        color: #bababa;
      }
    }

  }

  .dict-description {
    padding: 0 10px;

    ul {
      padding: 5px 10px 0 12px;
      height: 65px;
      overflow: auto;
      list-style: square;

      li {

        word-wrap: break-word;
        list-style: square;
        color: #8a8a8a;
        font-size: 11px;
      }
    }

    p {
      margin: 0;
      font-size: 11px;
      color: #8a8a8a;
      padding: 0 10px 0 0;

      span:first-child {
        font-weight: bold;
      }
    }
  }

  .item-footer {
    position: relative;
    background: #ececec;

    &__performed {
      position: absolute;
      top: -10px;
      right: 10px;
      //background: green;
      border-radius: 2px;
      color: #fff;
      font-size: 12px;

      span {
        background: gray;
        padding: 5px 13px;
        border-radius: 4px;
      }
    }

    &__content {
      p {
        margin: 0;
        font-size: 11px;
        color: #8a8a8a;

      }

      padding: 15px 20px 10px;

      .client {
        p {
          font-size: 11px;
          color: #8a8a8a;
          overflow: hidden;
          max-height: 65px;

          ::v-deep span {
            b {
              font-size: 11px;
              color: #24387f;
              font-weight: normal;
            }
          }
        }
      }

      .employee {
        p {
          font-size: 11px;
          color: #8a8a8a;
          max-height: 65px;
          overflow: hidden;

          ::v-deep span {

            b {
              font-size: 11px;
              color: red;
              font-weight: normal;
            }
          }
        }
      }
    }
  }

  .client-span {
    color: #24387f;
  }

  .employee-span {
    color: red;
  }

}

.i {
  font-style: normal !important;
}

.high-lite-block {
  display: grid;
  gap: 10px;
}
