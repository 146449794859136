

















































.my-slider{
  ::v-deep .swiper-button-next,
  ::v-deep .swiper-button-prev {
    transition: .3s;
    background: rgba(37, 37, 37, 0.5);
    border-radius: 50px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    color: #fff;
    &:after{
      font-size: 15px;
    };
  }
  ::v-deep .swiper-button-disabled{
    opacity: 0;
  }
  ::v-deep .swiper-slide {
    padding: 7px;
    height: 100% ;

  }

}

