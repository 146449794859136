
















.content{
  padding: 15px;
}
ul{
  li{
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
