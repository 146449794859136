



















































































































































































































































































.checklists-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  overflow: hidden;

  @media (max-width: 1700px) {
    & {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
  @media (max-width: 1500px) {
    & {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  @media (max-width: 1200px) {
    & {
      grid-template-columns: 1fr 1fr;
    }
  }

  h4, h5 {
    color: #555;
    font-weight: 500;
    line-height: 1.3;
    margin-bottom: 5px;
  }

  h4 {
    font-size: 12px;
  }

  h5 {
    font-size: 10px;
    position: relative;

    i.el-icon-edit {
      position: absolute;
      right: 20px;
      font-size: 13px;
      top: -1px;
    }
  }

  .checklist {
    max-width: 450px;
    width: 100%;
    background: #fff;
    padding-bottom: 75px;
    position: relative;
    border-radius: 5px;
    user-select: none;
    border: solid 1px #8080802e;
    box-shadow: 2px 2px 7px 0 #8080802e;
    @media (max-width: 1700px) {
      & {
        max-width: 330px;
      }
    }
    @media (max-width: 1500px) {
      & {
        max-width: 400px;
      }
    }
    @media (max-width: 1200px) {
      & {
        max-width: unset;

        p {
          max-width: 480px !important;
        }
      }
    }

    .checklist-description {
      color: #555;
    }

    p {
      word-wrap: break-word;
      max-width: 320px;
      max-height: 200px;
      overflow: auto;
      margin: 0;
    }

    ::v-deep .el-tooltip {
      &__popper.is-dark {
        font-size: 40px;
        background: red;
      }

    }

    .item-header {
      padding: 10px 10px 5px;
      display: flex;
      justify-content: space-between;

      .btns-actions {
        display: flex;

        & > i {
          margin-right: 5px;
          height: 14px;
        }

        & > span {
          line-height: 1;
        }

        i {

          color: #8a8a8a;
        }

      }

      button {
        cursor: pointer;
        padding: 5px;
        border: none;
        background: none;

        i {
          color: #bababa;
        }
      }
    }

    .dictionaries {
      padding: 0 10px 25px 10px;

      ul {
        padding: 5px 10px 0 12px;
        max-height: 270px;
        overflow: auto;
        list-style: square;
        margin-bottom: 0;

        li {
          position: relative;
          display: flex;
          align-items: center;
          list-style: none;
          color: #8a8a8a;
          font-size: 11px;
          line-height: 2.4;
          margin-bottom: 5px;

          & > div {
            &:first-child {
              min-width: 35px;
            }

            &:last-child {
              line-height: 1.3;
            }
          }

        }
      }

      p {
        font-size: 11px;
        color: #8a8a8a;
        padding: 0 10px 0 0;

        span:first-child {
          font-weight: bold;
        }
      }
    }

    .footer-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: #ececec;
    }

    .item-footer {
      position: relative;

      &__performed {
        display: flex;
        top: -24px;
        left: -10px;
        border-radius: 2px;
        font-size: 12px;
        text-align: center;
        height: 20px;
        justify-content: flex-end;

        > span {
          display: inline-block;
          height: 100%;
          line-height: 1.7;
          border-radius: 4px;
          width: 30px;
          margin-right: 5px;
          color: #fff;

          &:last-child {
            background: red;
          }

          &:first-child {
            background: green;
          }
        }
      }

      &__content {
        padding: 15px 10px 10px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        ul {
          margin: 0;

          li {
            font-size: 11px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #8a8a8a;
          }
        }


      }
    }

    .client-span {
      color: red;
    }

    .colleague-span {
      color: #24387f;
    }
  }
}


.add {
  text-align: right;
  margin-bottom: 10px;
}

.top-block {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    margin: 0;
  }
}

.search-block {
  margin-bottom: 20px;
}

.title h4{
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
  color: #555;
}
