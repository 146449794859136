
















































































































































































































.upload-calls {
  overflow: hidden;
  padding: 15px;
  background: #fff;
  min-height: 86vh;

  .title {
    h2 {
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 700;
      color: #555;
    }
  }

  .get-info {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    min-height: 300px;
    gap: 50px;
  }

  .step-btn {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    button {
      font-size: 12px !important;
      font-weight: normal;
    }

  }
}

.disabled {
  opacity: 0.3 !important;
  pointer-events: none;
}

::v-deep {
  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body, .el-dialog {
    border-radius: 4px !important;
  }

  .modal-content {
    h3 {
      color: #555;
      text-align: center;
      margin-bottom: 20px;
    }

    button {
      width: 100px;
      margin: 0 auto;
    }

    .succ {
      display: grid;
      justify-content: center;
    }

    //min-height:190px;
  }

  .el-progress-bar__innerText {
    color: #fff !important;
  }
}

