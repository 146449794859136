































































.list {
  overflow: auto;
  width: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .btn {
      height: 26px;
      font-size: 12px;
      padding: 2px 10px;
      margin: 0 2px;
      font-weight: 400;
    }
  }

  h5 {
    margin: 0 0 10px;
    color: #555;
  }
}

