






























































































































































.item {
  border-radius: 5px;
  user-select: none;
  border: solid 1px #8080802e;
  box-shadow: 2px 2px 7px 0px #8080802e;

  .item-body__progress {
    padding: 0 10px 10px;
  }


  .item-header {
    padding: 10px 10px 0;
    display: flex;
    justify-content: space-between;

    h4 {
      margin: 0;
      color: #555;
      font-weight: 500;
      font-size: 13px;
    }

    button {
      cursor: pointer;
      padding: 5px;
      border: none;
      background: none;

      i {
        color: #bababa;
      }
    }
  }

  .item-body {
    padding: 0 10px;
    margin-bottom: 10px;
    max-height: 300px;
    min-height: 300px;
    overflow: auto;

    &__positive-list, &__negative-list {
      margin: 0 0 0px 20px;

      li {
        cursor: pointer;
        list-style: disc;
        margin: 0;
        color: #555;
        line-height: 1.5;
        font-size: 11px;

        &::marker {
          color: green;;
        }
      }
    }

    &__negative-list {
      padding: 0 10px 25px 0px;

      li {
        &::marker {
          color: red;;
        }
      }
    }

    &__progress {
      margin: 0px 0;
    }
  }

  .item-footer {
    position: relative;
    background: #ececec;

    &__performed {
      position: absolute;
      top: -10px;
      right: 10px;

      span {
        color: #fff;
        font-size: 12px;
        border-radius: 2px;
        padding: 5px 5px;
        background: gray;
      }
    }

    &__content {

      padding: 15px 20px 10px;

      .points {
        font-size: 11px;
        color: #8a8a8a;
      }

      .score {
        font-size: 11px;
        color: #8a8a8a;
      }
    }
  }
}

.null-style {
  background: inherit !important;
  box-shadow: none;
  border: none;
  padding: 3px 0 !important;
}

.revers-dict {
  max-width: 200px;
  color: #555;

  font-weight: normal;

  span {
    font-weight: bold;
  }
}

.reverse-icon {
  transform: rotate(90deg);
  color: red;
  line-height: 1;
}
