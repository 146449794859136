





































































































































.call-list__item {
  margin-bottom: 3px;
  overflow: auto;
  display: grid;
  grid-template-columns: 140px  90px 170px 120px 65px 300px 1fr;
  align-items: center;
  height: 40px;
  line-height: 48px;
  background-color: #fff;
  color: #b5b5b5;
  cursor: pointer;
  border-bottom: 1px solid #ebeef5;
  font-size: 13px;
  font-weight: 500;
  transition: border-bottom-color .3s;
  outline: none;
  border-radius: 7px;
  padding: 0 10px;
  gap: 5px;

  div {
    padding: 0 5px;
    display: grid;
    gap: 3px;
    align-items: center;

    span {
      line-height: 1;
      text-align: center;

      &:first-child {
        max-width: 105px;
        text-align: start;
        font-size: 9px;
      }

      &:last-child {
        display: flex;
        align-items: center;
        height: 20px;
        font-weight: normal;
        color: #000;
        font-size: 12px;
      }
    }

    p {
      width: 100%;
      max-width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
      font-weight: normal;
      color: #000;
      font-size: 12px;
    }
  }

  &-btns {
    display: flex !important;
    justify-content: flex-end;

    button {
      font-size: 12px;
      font-weight: 400;
      background: inherit;
      padding: 2px 10px;
      margin: 0 2px;


      &:hover {
        span {
          color: #fff !important;
        }
      }

      span {
        font-size: 12px;
        font-weight: 400;
      }

      &:first-child {
        border-color: #d90000;

        span {
          color: #d90000;
        }

        &:hover {
          background: #d90000;
          border-color: #d90000;
        }
      }

      &:last-child {
        min-width: 82px;

        span {
          color: #24387f;
        }

        &:hover {
          background: #24387f;
          border-color: #24387f;

        }
      }

      &:first-child[disabled] {
        border-color: #8a8a8a !important;

        span {
          color: #8a8a8a !important;
        }

        &:hover {
          background: inherit !important;
          border-color: #8a8a8a !important;

          span {
            color: #8a8a8a !important;
          }
        }
      }
    }

    .upload {
      background: #24387f;

      ::v-deep {
        i {
          color: #fff !important;
        }
      }

    }
  }


  ::v-deep {
    .el-input__prefix, .el-input__suffix {
      display: none;
    }

    .el-input, .el-date-editor, .el-select {
      padding: 0;
    }

    input {
      padding: 0;
      border: none;
      background: inherit;
      height: 20px;
      font-size: 12px;
      cursor: pointer;
      width: 100%;
      font-family: inherit;

      &::-webkit-input-placeholder {
        //color: #24387f;
        //color: #a20000;
        color: #8d8d8d;

      }
    }
  }
}

.separator {
  height: 100%;
  width: 1px;
  background: #ebeef5;
}

.spinner-btn {
  ::v-deep {
    .el-icon-loading {
      font-size: 20px !important;
    }
  }

  i {
    font-size: 10px !important;
  }

}
