

















































































.calls {
  padding: 0 30px;
  box-sizing: border-box;
  text-align: center;
  &-header {
    display: grid;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: -20px;
    gap: 20px;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

    div {
      &:first-child {
        text-align: left;
      }
    }
  }
  &-body {
    height: 50vh;
    overflow-y: auto;
    &__call {
      display: grid;
      gap: 20px;
      align-items: center;
      border-bottom: 1px solid #ddd;
      cursor: pointer;
      transition: background 200ms linear;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      div {
        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: nowrap;
        padding: 5px 0;
        font-size: 11px;
        &:first-child {
          text-align: left;
        }
      }
      &:hover {
        background: #f5f7fd;
      }
    }
  }

  &-call {
    &__body {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

      //height: 80vh;
      //overflow-y: auto;

    }


    ::v-deep .el-button {
      background: #000;
    }

    ::v-deep .el-statistic {
      .head {
        font-size: 12px;
      }
      .con {
        margin-bottom: 3px;
        padding: 0 10px;
        .number {
          font-size: 14px;
        }

      }
    }
  }

  .popover-button {
    color: #24387f;
    cursor:pointer;
    font-size: 12px;
    padding: 0 10px;
  }
}

