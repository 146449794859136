





















































































































.question-form-container {
  h4 {
    text-align: center;
  }
  .form-fields {
    > .el-row {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      > .el-col {
        &:first-child{
          padding-right: 10px;
          text-align: right;
        }
      }
    }

  }
  .command-buttons {
    text-align: center;
    margin-bottom: 25px;
  }
}

