













































































































































































































h5 {
  margin: 10px 0;
  color: #555;
}

.groups {
  display: grid;
  //flex-wrap: nowrap;
  //overflow-x: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  @media (max-width:2250px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media (max-width:1800px) {
    grid-template-columns: 1fr 1fr 1fr ;
  }

  @media (max-width:1400px) {
    grid-template-columns: 1fr 1fr ;
  }

  .group {
    cursor: pointer;
    position: relative;
    min-width: 400px;
    padding: 5px 0 0 0;
    margin-bottom: 10px;
    user-select: none;
    border: solid 1px #8080802e;
    box-shadow: 2px 2px 7px 0 #8080802e;
    border-radius: 5px;
    margin-right: 20px;

    .title {
      padding: 5px 8px;
    }

    h4 {
      margin: 0 0 10px 0;
      color: #555;
      font-weight: 500;
      line-height: 1.3;
      font-size: 12px;
      text-transform: uppercase;
      width: 90%;
    }

    .color {
      height: 4px;
      width: 100%;
    }

    .commands {
      color: #8a8a8a;
      cursor: pointer;
      position: absolute;
      right: 8px;
      top: 8px;

      .el-icon-edit {
        display: inline-block;
        margin-right: 5px;
      }
    }

    h6 {
      margin: 10px 0 10px 8px;
    }

    .group-dictionaries {
      background: #fff;
      padding: 5px 8px;
      height: 135px;
      overflow-y: auto;


      ul {
        margin-left: 15px;

        li {
          position: relative;
          font-size: 12px;
          color: #4a4a4a;

          &:before {
            position: absolute;
            width: 3px;
            height: 3px;
            top: 7px;
            left: -13px;
            content: '';
            border-radius: 50%;
            background: rgb(25, 198, 3);
          }
        }
      }
    }
  }
}

::v-deep {
  .el-row {
    &:before {
      display: none;
    }
  }
}
