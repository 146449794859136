
























































.location-form-container {
  text-align: center;
  > *:not(:first-child) {
    margin-bottom: 25px;
  }
}

