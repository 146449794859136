



































































































































































.header-calls {
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ::v-deep .el-input__inner {
    height: 30px;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 10px;

    .select {
      width: 100px;
      max-width: 65px;

      ::v-deep .el-input__inner {
        border: none;
        background: inherit;
        height: 40px;
        font-weight: 700;
        font-family: inherit;
        padding: 0;
        color: #24387f;
        font-size: 16px;
      }

      ::v-deep .el-input {
        width: 70px;
        display: flex;
        align-items: center;
        gap: 10px;

      }

      ::v-deep .el-select__caret {
        font-size: 11px;
      }

      ::v-deep .el-input__suffix {
        display: none;
      }
    }

    .data-picker {
      ::v-deep .el-input__inner {
        height: 30px;
      }

      ::v-deep .el-input__icon {
        display: flex;
        align-items: center;
      }

      ::v-deep .el-range-separator {
        display: flex;
        align-items: center;
      }

      ::v-deep .el-range-input {
        width: 79%;
      }

      ::v-deep .el-date-editor--datetimerange {
        width: 350px;
      }
    }
  }

  .counts-call {
    & > div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 4px;

      .selectCountCalls {
        width: 20px;

        ::v-deep .el-input__inner {
          padding: 0 0 0 0px;
          height: 20px;
          text-align: center;
          font-weight: bold;
          color: #24387f;
          background: inherit;
          border: none;
        }

        ::v-deep .el-input__suffix-inner {
          display: none;
        }
      }
    }
  }

  p {
    margin: 0;
  }

  h4 {
    margin: 0;
    color: #252525;
    font-size: 15px;
  }

  margin-bottom: 20px;
}

.hideDatePicker {
  position: absolute;
  opacity: 0;
  z-index: -1111;
}

::v-deep .el-select-group__title {
  font-weight: bold;
  font-size: 12px;
}

::v-deep .el-select-dropdown__item {
  font-size: 12px;
}

.no-calls {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .el-select {
  i {
    line-height: 0;
  }
}
