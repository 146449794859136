@import "./project-variables.scss";

* {
  box-sizing: border-box;
  @include MyScrollBar;
  &:before, &:after {
    box-sizing: border-box;
  }

}

h1, h2, h3, h4, h5, h6, ul {
  padding: 0;
  margin: 0 0 32px 0;
}

ul {
  list-style: none;
}

html, body {
  font-size: 16px;
}

body {
  position: relative;
  min-width: 320px;
  margin: 0;
  font-family: 'Gotham Pro', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  font-size: 14px;
  overflow-x: hidden;
  color: $--color-text-regular;

}

p {
  padding: 0;
  margin: 0 0 24px 0;
  font-size: 14px;
  line-height: 1.44;
  color: $--color-text-primary;
}

b {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.44;
}

i {
  font-style: italic;
  line-height: 1.44;
}

h1 {
  font-size: 26px;
  font-weight: 400;
}

h2 {
  font-size: 22px;
  font-weight: 400;
}

h3 {
  font-size: 20px;
  font-weight: 700;
}

h3.light {
  font-weight: 400;
}

h4 {
  font-size: 18px;
  font-weight: 700;
}

h4.light {
  font-weight: 400;
}

h5 {
  font-size: 14px;
  font-weight: 700;
}

input {
  font-size: 14px;
  font-weight: 400;
}

button {
  font-size: 16px;
  font-weight: 400;
}

a {
  font-size: 14px;
  color: $--color-primary;
  text-decoration: none;
  transition: all .4s;
}

td {
  font-size: 14px;
  font-weight: 400;
}

i.el-icon-edit,
i.el-icon-refresh,
i.el-icon-circle-plus-outline,
i.el-icon-more-outline,
i.el-icon-delete,
i.el-icon-arrow-down,
i.el-icon-arrow-up{
  cursor: pointer;
}

.el-button.is-round {
  border-radius: 10px;
}
input.el-input__inner {
  padding-left: 15px;
}


.el-dialog__body,
.el-popover,
.el-table .cell {
  word-break: normal;
}

.horizontal-page-menu {
  padding: 0 0 10px 0;
  box-shadow: 0 2px 2px -1px #ddd;
  margin-bottom: 10px;
}


.page-command-buttons {
  display: inline-flex;
  background: #fff;
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 10px;
  padding: 0 10px;
  justify-content: center;
  align-items: center;
}

.page-command-buttons  i.el-icon-refresh {
  margin-top: 0;
  margin-left: 5px;
  color: #b5b5b5;
  font-size: 24px;
}

.el-popover p {
  margin-bottom: 5px;
}
.el-popover button{
  margin: 0 5px;
}
.page-command-buttons  .location i.el-icon-more-outline {
  margin: 5px 0 0 10px;
}
.el-popover{
  overflow: hidden !important;
  box-shadow: 0 2px 12px 0 #000;
}

.popoverTitle{
  color: #222;
  font-weight: bold;
  text-align: center !important;
}
.popoverButton{
  margin-top: 0;
  color:#24387f ;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  span{
    color:#24387f ;
  }
  &.popoverButtonSuccess{
    &:hover{
      background: red;
      border-color: red;
    }
    background: #c90000;
    border-color: #c90000;
    span{
      color: #fff;
      font-weight: bold;
    }
  }
  &.popoverButtonCancel{
    &:hover{
      background: #2e4aa9;
      border-color: #2e4aa9;
    }
    background: #24387f ;
    border-color: #24387f;
    span{
      color: #fff;
      font-weight: bold;
    }
  }
}

.el-button {
  width: auto;
  height: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12.5px 24px;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  color: $--color-primary;
  line-height: 1.5;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  background-color: $--color-white;
  border: 1px solid $--color-primary-light-6;
  transition: all .4s;

  &:hover {
    color: $--color-primary;
    background-color: $--color-primary-light-7;
    border-color: $--color-primary-light-7;
  }

  i[class^="fa"] {
    margin-right: 8px;
  }

  &.is-disabled {
    color: $--color-text-regular-6;
    background-color: $--color-white;
    border-color: $--color-secondary-gray-hover;

    &:hover {
      color: $--color-text-regular-6;
      background-color: $--color-white;
      border-color: $--color-secondary-gray-hover;
    }
  }

  &.is-plain {
    background-color: $--color-primary-light-9;
    border-color: $--color-primary-light-9;

    &.is-disabled {
      color: $--color-text-regular-6;
      background-color: $--color-secondary-gray-hover;
      border-color: $--color-secondary-gray-hover;

      &:hover {
        color: $--color-text-regular-6;
        background-color: $--color-secondary-gray-hover;
        border-color: $--color-secondary-gray-hover;
      }
    }

    &:hover {
      background-color: $--color-primary-light-7;
      border-color: $--color-primary-light-7;
      color: $--color-primary;
    }
  }

  &--medium {
    padding-top: 12.5px;
    padding-bottom: 12.5px;
  }

  &--small {
    padding-top: 8.5px;
    padding-bottom: 8.5px;
    text-transform: none;
    font-weight: 400;
  }

  &--mini {
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 400;
    text-transform: none;
  }

  &--primary {
    color: $--color-white;
    background-color: $--color-primary;
    border-color: $--color-primary;

    &.is-disabled {
      color: $--color-text-regular-6;
      background-color: $--color-secondary-gray-hover;

      &:hover {
        color: $--color-text-regular-6;
        background-color: $--color-secondary-gray-hover;
      }
    }

    &:hover {
      color: $--color-white;
      background-color: $--color-primary-hover;
      border-color: $--color-primary-hover;
    }
  }
}


.my-container{
  padding: 20px;
  max-width: 1800px;
  width: 100%;
}
.el-tooltip__popper{
  font-size: 10px;
}
.el-popover {
  max-height: 95vh;
  overflow-y: auto;
}

.item-body__positive-list{

}

.tool-tip-client,.tool-tip-employee{
  max-width: 200px;
  font-size: 10px !important;
  i{
    color: #555;
    font-style: normal;
  }
  b{
    font-size: 10px;
    font-weight: normal;
  }
}
.tool-tip-employee div{
  color: red;
  b{
    color: red;
  }
}
.tool-tip-client div{
  color: #24387f;
  b{
    color: #24387f;
  }
}

.el-drawer__header{
  margin-bottom: 10px !important;
}