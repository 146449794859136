


































































@import "./src/assets/css/project-variables";

.pagination {
  position: relative;
  margin-top: 40px;
  width: 100%;
  .counts-call {
    position: absolute;
    right: 0;
    display: flex;
    & > div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 4px;
      p {
        margin: 0;
      }

      .selectCountCalls {
        width:75px;

        ::v-deep .el-input{
          &__suffix-inner{

          }
          &__icon {
            line-height: 1;
          }

        }
      }
    }

    p {

    }
  }
}
::v-deep .el-pagination{
  .number{
    background: #fff !important;
    &.active{
      background: #24387f !important;
    }
  }
}

