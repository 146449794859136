



































































































































































































































































































.search {
  .general {
    width: 100%;
    background: #fff;
    text-align: center;
    .el-form {
      width: 50%;
      margin: 0 auto;
    }
    .queries {
      width: 100%;
      display: flex;
    }
  }
  .forms {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  &-form {
    display: flex;
    flex-direction: column;
    align-items: center;

    ::v-deep .el-form {
      padding: 20px;
      background: #fff;
      width: 100%;

      &-item {
        display: flex;
        width: 100%;

        &__label {
          width: 30%;
        }

        &__content {
          width: 70%;

          > * {
            width: 100%;
          }
        }
      }
    }
  }
  .results {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .results1, .results2 {
      margin-top: 25px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
    h4 {
      text-align: center;
      margin-bottom: 10px;
      margin-top: 10px;
    }
    ::v-deep .el-collapse {
      width: calc(100% - 50px);
      &-item {
        &__header {
          color: #555;
          padding-left: 10px;
          font-size: 14px;
        }
        &__content {
          color: #555;
          font-weight: 300;
          font-size: 13px;
        }
        .highlight {
            text-align: left;
          h4 {
            margin-bottom: 10px;
            margin-top: 10px;
            color: #555;
            font-size: 14px;
            text-align: left;
          }
          //display: grid;
          //grid-template-columns: 1fr 1fr 1fr;
          padding: 10px;
          b {
            color: red;
            font-size: 12px;
          }
          & div {
            border-bottom: 1px solid #ddd;
          }
        }

      }

    }
  }
}

