


































@import "./src/assets/css/project-variables";

.page-error {
  height: calc(100vh - 190px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    margin-bottom: 8px;
  }

  p {
    color: $--color-text-regular-6;
    margin-bottom: 48px;
  }

  @media only screen and (max-width: 1480px) {
    height: calc(100vh - 140px);

    img {
      width: 25%;
    }

    p {
      margin-bottom: 24px;
    }
  }
}

