
































.switch{
  min-width: 170px;
}
h6 {
  font-size: 15px;
  margin-bottom: 10px;
  color: #555;
}
::v-deep{
  .el-radio-button__inner{
    font-size: 12px;
    font-weight: normal;
    width: 85px;
  }
}
