

















































































































































.wrapper {
  max-width: 1150px;
}

.category-checklists-container {
  margin: 15px 20px;
  max-height: 491px;
  overflow-y: auto;
  color: #252525;

  h4 {
    margin-bottom: 0px;
    font-size: 13px;
    display: flex;
    gap: 10px;
    align-items: center;
    font-weight: 450;

    i {
      line-height: 1;
      font-size: 12px;
      color: #555;
    }
  }

  .el-carousel__item {
    &.is-active {
      background: #fff;
    }

    .checklists-list {
      border-radius: 2px;
      max-height: 400px;
      overflow-y: auto;
      box-shadow: inset 0 0 4px 0 rgb(0 0 0 / 13%);

      ul {
        padding: 5px 10px;

        li {
          display: flex;
          align-items: center;
          gap: 10px;
          color: #555;
          font-weight: 450;
          line-height: 1.3;
          font-size: 12px;
          font-family: inherit;
          margin: 3px 0;

          .dictionary-color {
            height: 15px;
            padding: 0 5px;
            background: gray;
            border-radius: 4px;
            display: flex;
            justify-content: center;

            span {
              text-align: center;
              justify-content: center;
              color: #fff;
              font-style: normal;
            }
          }
        }
      }
    }
  }
}

.tab-content {
  display: grid;
  grid-template-columns: 1.1fr 1fr;
  box-sizing: border-box;
  gap: 10px;
}

::v-deep {
  .el-tabs__item {
    font-size: 13px !important;
    line-height: 2;
    height: 30px;
  }

  .el-tabs__nav-prev {
    i {
      line-height: 1 !important;
      transform: translateY(-7px);
    }
  }

  .el-tabs__nav-next {
    i {
      line-height: 1 !important;
      transform: translateY(-7px);
    }
  }
}
.disabled{
  opacity: 0.3;
  text-decoration: line-through;

}
