

































































.integrations {


  height: calc(100vh - 135px);

  &__title {
    padding: 10px;
    background: #fff;
    font-size: 18px;
    font-weight: 700;
    color: #555;

    h3 {
      margin-bottom: 10px;
    }
  }

  ::v-deep {
    .el-tabs--top {

      background: #fff;
    }

    .el-tabs__item {
      font-weight: bold;

      min-width: 150px;
      text-align: center;
    }
  }

  .vats {
    .controls, .calls, .setting {
      border-top: solid 2px #E4E7ED;
      padding: 10px;
    }

    ::v-deep {

      .el-tabs__nav-scroll {
        display: flex;
        justify-content: flex-end;

        .el-tabs__item {
          font-weight: normal;

          text-align: center;
        }

      }

      .el-tabs__nav-wrap::after {
        background: inherit !important;
      }
    }
  }
}
