



























































































@import "./src/assets/css/project-variables";

.box-card {
  padding: 2rem;
  position: relative;
  .el-icon-delete, .el-icon-edit {
    position: absolute;
    top: 7px;
    cursor: pointer;
  }
  .el-icon-delete {
    right: 5px;
  }
  .el-icon-edit {
    right: 24px;
  }
  .dictionary {
    font-size: 12px;
    h2 {
      position: relative;
      > span {
        position: absolute;
        right: 0;
        width: 10px;
        height: 10px;
        display: inline-block;
      }
    }
    .el-row {
      > .el-col:last-child {
        text-align: right;
      }
    }
    .information {
      position: relative;
      .el-icon-info {
        position: absolute;
        right: 12px;
        top: -34px;
        cursor: pointer;
        color: #999999;
      }
    }
    .el-button.is-round {

      padding: 5px 15px;
      font-size: 10px;
    }
    .el-icon-star-on {
      position: relative;
      right: 22px;
      font-size: 24px;
    }
  }
}

