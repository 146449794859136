



































::v-deep .el-progress {
  display: grid;
  grid-template-columns: 5fr 1fr;
  align-items: center;
  &__text {
    display: inline-block;
  }
  &-bar {
    padding: 0;
  }
}
