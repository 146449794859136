

































































































































































































































@import "src/assets/css/project-variables";

.category-dictionaries-container {
  ::v-deep .el-loading-spinner {
    .circular {
      width: 15px;
      height: 15px;
      top: 14px !IMPORTANT;
      position: relative;
    }
  }

  ::v-deep .el-loading-parent {
    &--relative {
      width: 20px;
    }
  }

  margin: 15px;

  color: #252525;
  padding-bottom: 0;

  h4 {

    margin-bottom: 0;
    font-size: 13px;
    display: flex;
    gap: 10px;
    align-items: center;
    font-weight: 450;

    i {
      line-height: 1;
      font-size: 12px;
      color: #555;
    }
  }
}

.dictionary-title {

  > .el-col {
    text-align: center;
    margin-bottom: 10px;
    font-weight: 450;

    &:first-child {
      text-align: left;
    }
  }

}

.dictionary {
  > .el-col {
    text-align: center;

    &:first-child {
      text-align: left;
    }

    > span {
      display: inline-block;
      width: 20px;
      height: 20px;
    }
  }
}

.dictionary-list {
  border-radius: 2px;
  max-height: 435px;
  overflow-y: scroll;

  .legend_dict {
    font-size: 10px;
    color: rgba(85, 85, 85, 0.74);
    margin-bottom: 0;
  }

  ul {
    padding: 5px 0;
    margin-bottom: 0;
    padding-right: 10px;
    display: grid;

    .li-wrapper {
    display: grid;
    }

    li {

      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 3px 0px 0 10px;
      position: relative;

      .show-call-dialog {
        color: $--color-primary;
        cursor: pointer;
        font-size: 11px;
      }

      .el-icon-sort {
        position: absolute;
        top: 5px;
        left: -10px;
        transform: rotate(90deg);
        color: red;
        font-weight: 450;
      }


      span {
        font-weight: normal;
      }

      div {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #555;
        line-height: 1.3;
        font-size: 12px;
        font-family: inherit;
      }

      > span:last-child {
        font-weight: normal;
      }

      .dictionary-color {

        width: 35px;
        height: 15px;
        background: gray;
        border-radius: 4px;
        display: flex;
        justify-content: center;

        span {
          text-align: center;
          justify-content: center;
          color: #fff;
          font-style: normal;
        }
      }

      ::v-deep .category-calls .popover-button {
        padding: 0 2px;
      }
    }
  }
}

.separator {
  height: 1px;
  width: 100%;
  margin: 10px 0;
  background: #bebebe54;
}
